// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run bin/copy-shared-to-packages.sh
 */

/**
 * Generates an array of number between 2 numbers with an option step.
 * Example: range(10,15) return [10,11,12,13,14,15]
 * Example: range(2,10,2) returns [2,4,6,8,10]
 * @param from starting number
 * @param to ending number
 * @param step increment, default: 1
 */
export const range = (from: number, to: number, step = 1): number[] =>
  [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);
