// Copyright © 2023 CATTLEytics Inc.
import { useCallback, useMemo, useState, VFC } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import AlertSuccessForModal from '../common/components/AlertSuccessForModal';
import ButtonModalCancel from '../common/components/ButtonModalCancel';
import ButtonModalSave from '../common/components/ButtonModalSave';
import { api } from '../common/utilities/api';
import { ApiResourceV1, HttpMethod, QueryKey, Shift, User, UserSchedule } from '../shared';
import UserAutocomplete from '../users/components/UserAutocomplete';
import { DailyInputs } from './date-inputs/DailyInputs';

let successTimer: ReturnType<typeof setInterval>;

type Props = {
  onCancel?: () => void;
  onSuccess?: (data?: UserSchedule[]) => void;
  selectedDate?: Date;
};

const ScheduleUserForm: VFC<Props> = ({ selectedDate, onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [users, setUsers] = useState<User[]>();
  const [shift, setShift] = useState<Shift>();
  const [dates, setDates] = useState<Date[]>([]);
  const [createAnother, setCreateAnother] = useState(false);

  const { data: shifts } = useQuery<Shift[]>(
    [QueryKey.Shifts],
    () => api(HttpMethod.Get, ApiResourceV1.Shifts),
    {},
  );

  const { isLoading: saveIsLoading, mutateAsync: saveAsync } = useMutation<
    UserSchedule[] | undefined
  >(
    () => {
      const method = HttpMethod.Post;
      const path: string = ApiResourceV1.UserSchedulesBulk;

      return api(method, path, {
        body: {
          userIds: (users ?? []).map((u) => u.id),
          shiftId: shift?.id,
          dates: dates.map((date) => date.toISOString()),
        },
      });
    },
    {
      onSuccess,
    },
  );

  const isLoading = useMemo(() => saveIsLoading, [saveIsLoading]);

  const resetForm = useCallback(
    (form: HTMLFormElement) => {
      form.classList.remove('was-validated');
      form.reset();
      setSuccessMessage(t('scheduleUserForm|scheduleSavedMessage'));
      successTimer = setInterval(() => setSuccessMessage(''), 5000);
      setValidated(false);
    },
    [t, setSuccessMessage, setValidated],
  );

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      clearInterval(successTimer);
      setSuccessMessage('');

      const form = event.currentTarget;
      const valid = form.checkValidity();

      setValidated(true);

      if (!valid || !users) {
        // mark the form as having its validity checked
        return;
      }

      setErrorMessage('');
      try {
        await saveAsync();
        if (createAnother) {
          resetForm(form);
        } else {
          onCancel?.();
        }
      } catch (err) {
        setErrorMessage(t('Schedule could not be saved.'));
      }
    },
    [t, saveAsync, users, createAnother, onCancel, resetForm],
  );

  const onDatesChange = useCallback((dates): void => setDates(dates), [setDates]);

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      <Form.Group className="form-group mb-3" controlId="formUser">
        <Form.Label>{t('scheduleUserForm|userLabel')}</Form.Label>
        <UserAutocomplete
          disabled={isLoading}
          id={'user'}
          multiple={true}
          name={'userId'}
          onSelect={(selected): void => {
            if (!selected) {
              return setUsers(undefined);
            }
            const nextSelected = Array.isArray(selected) ? selected : [selected];
            setUsers(nextSelected);
          }}
          required={true}
          selected={users}
          validated={validated}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>

      <DailyInputs
        isLoading={isLoading}
        onChange={onDatesChange}
        selectedDate={selectedDate}
        showLabels
        validated={validated}
      />

      <Form.Group className="form-group mb-3" controlId="formShift">
        <Form.Label className="text-nowrap ">{t('scheduleUserForm|selectShiftLabel')}</Form.Label>
        <Form.Select
          disabled={isLoading}
          name="shift"
          onChange={(e): void => setShift(shifts?.find(({ id }) => id === Number(e.target.value)))}
          required
          value={shift?.id}
        >
          <option value="">{t('scheduleUserForm|selectShiftDefaultOption')}</option>
          {shifts?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <AlertErrorForModal message={errorMessage} />
      <AlertSuccessForModal message={successMessage} />
      <div className="modal-footer modal-footer-in-form flex-wrap-reverse flex-sm-nowrap">
        <ButtonModalCancel disabled={isLoading} onClick={onCancel} />
        <Stack className="flex-grow-1 flex-sm-grow-0" direction="horizontal" gap={2}>
          <Form.Check
            checked={createAnother}
            id="createAnother"
            label={t('scheduleUserForm|createAnotherLabel')}
            onChange={(e): void => setCreateAnother(e.target.checked)}
            type="checkbox"
          />
          <ButtonModalSave busy={saveIsLoading} disabled={isLoading} />
        </Stack>
      </div>
    </Form>
  );
};

export default ScheduleUserForm;
