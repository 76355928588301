// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run bin/copy-shared-to-packages.sh
 */

/**
 * Simple function to return a primary tag and name in the format "tag - name".
 * If name is omitted: "tag"
 * If primary tag is omitted: ""
 * @param primaryTag
 * @param name
 */
export function nameTag(primaryTag?: string, name?: string): string {
  if (!primaryTag) {
    return '';
  }
  return `${primaryTag}${name ? ` - ${name}` : ''}`;
}
