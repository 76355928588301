// Copyright © 2023 CATTLEytics Inc.

import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AnimalEventTypeAutocomplete from '../../common/components/AnimalEventTypeAutocomplete';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import AnimalEvent from '../../common/entities/animalEvent';
import AnimalEventType from '../../common/entities/animalEventType';
import Pen from '../../common/entities/pen';
import { AnimalEventType as AnimalEventTypeEnum } from '../../common/enums';
import { Animal } from '../../shared';
import ArrivalForm from './forms/ArrivalForm';
import BredForm from './forms/BredForm';
import CalvingForm from './forms/CalvingForm';
import DiagnosisForm from './forms/DiagnosisForm';
import DriedOffForm from './forms/DriedOffForm';
import FreshForm from './forms/FreshForm';
import MoveForm from './forms/MoveForm';
import SoldForm from './forms/SoldForm';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Default value for event type
   */
  defaultEventType?: AnimalEventType;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback with saved event.
   */
  onSave?: (animalEvent?: AnimalEvent) => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;
}

/**
 * Event modal component for creating/editing events.
 */
const EventModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [animalEventType, setAnimalEventType] = useState<AnimalEventType | undefined>(
    props.defaultEventType,
  );

  const title = useMemo((): string => {
    if (!animalEventType) {
      return '';
    }

    switch (animalEventType.id) {
      case AnimalEventTypeEnum.Bred:
        return 'Breeding';
      case AnimalEventTypeEnum.Calving:
        return 'Calving';
      case AnimalEventTypeEnum.Move:
        return 'Move';
      case AnimalEventTypeEnum.Arrival:
        return 'Arrival';
      case AnimalEventTypeEnum.Fresh:
        return 'Fresh';
      case AnimalEventTypeEnum.DriedOff:
        return 'Dried Off';
      case AnimalEventTypeEnum.Diagnosis:
        return 'Diagnosis';
      case AnimalEventTypeEnum.Sold:
        return 'Sold';
      default:
        return '';
    }
  }, [animalEventType]);

  const form = ((): JSX.Element => {
    if (!animalEventType) {
      return <></>;
    }

    switch (animalEventType.id) {
      case AnimalEventTypeEnum.Bred:
        return <BredForm onCancel={props.onClose} onSubmitSuccess={props.onSave} />;
      case AnimalEventTypeEnum.Calving:
        return <CalvingForm onCancel={props.onClose} onSubmitSuccess={props.onClose} />;
      case AnimalEventTypeEnum.Move:
        return <MoveForm onCancel={props.onClose} onSubmitSuccess={props.onClose} />;
      case AnimalEventTypeEnum.Arrival:
        return <ArrivalForm onCancel={props.onClose} onSubmitSuccess={props.onClose} />;
      case AnimalEventTypeEnum.Fresh:
        return <FreshForm onCancel={props.onClose} onSubmitSuccess={props.onClose} />;
      case AnimalEventTypeEnum.DriedOff:
        return <DriedOffForm onCancel={props.onClose} onSubmitSuccess={props.onClose} />;
      case AnimalEventTypeEnum.Diagnosis:
        return <DiagnosisForm onCancel={props.onClose} onSubmitSuccess={props.onSave} />;
      case AnimalEventTypeEnum.Sold:
        return <SoldForm onCancel={props.onClose} onSubmitSuccess={props.onSave} />;
      default:
        return <></>;
    }
  })();

  return (
    <Modal
      fullscreen={'lg-down'}
      onClose={props.onClose}
      size={'xl'}
      title={`${props.eventId ? 'Edit' : 'Create'} ${title} Event`}
      visible={true}
    >
      {!animalEventType && (
        <Form.Group className="mb-3" controlId="formEventType">
          <Form.Label>
            {t('Event Type')} <Required />
          </Form.Label>
          <AnimalEventTypeAutocomplete
            id={'formEventType'}
            onSelect={(aet): void => setAnimalEventType(aet)}
            selected={animalEventType}
          />
          <Form.Control.Feedback type={'invalid'}>This field is required.</Form.Control.Feedback>
        </Form.Group>
      )}
      {form}
    </Modal>
  );
};

export default EventModal;
