// Copyright © 2023 CATTLEytics Inc.

import 'chartjs-adapter-date-fns';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, ReportDimUpToEvent } from '../../shared';

const lineColors = [
  'rgb(255, 160, 160)', // PastelPink
  'rgb(152, 251, 152)', // PaleGreen
  'rgb(160, 160, 255)', // Pastel Purple
  'rgb(255, 215, 0)', // Gold
  'rgb(255, 160, 122)', // LightSalmon
  'rgb(255, 105, 180)', // HotPink
  'rgb(135, 206, 235)', // SkyBlue
  'rgb(127, 255, 212)', // Aquamarine
  'rgb(160, 255, 255)', // Pastel Blue
];

const FirstBredEventChart = (): JSX.Element => {
  const [data, setData] = useState<ChartData<'scatter'>>();

  const { t } = useTranslation();

  const query = useQuery<Required<ReportDimUpToEvent>[]>([QueryKey.ReportDimUpToEvent], () =>
    api<Required<ReportDimUpToEvent>[]>(HttpMethod.Get, ApiResourceV1.ReportDimUpToEvent),
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ScatterController,
  );

  const options: ChartOptions<'scatter'> = {
    plugins: {
      legend: {
        title: {
          display: true,
          text: t('reproductionManagement|firstBredEventChart|legendTitle'),
          font: {
            size: 16,
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          parser: 'YYYY-MM-dd',
          tooltipFormat: 'MMM dd yyyy',
          displayFormats: {
            month: 'MMM yyyy',
          },
        },
        grid: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: t('reproductionManagement|firstBredEventChart|yAxisLabel'),
        },
        min: 0,
      },
    },
  };

  useEffect(() => {
    if (!query.data) {
      return;
    }
    const lactGroup: { [group: string]: { data: { x: number; y: number }[] } } = {};

    query.data.forEach((row) => {
      const { dimUpToEvent, bredDate, lactationGroup } = row;

      const dateObject = new Date(bredDate);

      if (!lactGroup[lactationGroup]) {
        lactGroup[lactationGroup] = { data: [] };
      }
      lactGroup[lactationGroup].data.push({
        x: dateObject.getTime(),
        y: dimUpToEvent,
      });
    });

    const datasets = Object.keys(lactGroup).map((group, index) => ({
      label: group,
      data: lactGroup[group].data,
      borderColor: lineColors[index % lineColors.length],
      backgroundColor: lineColors[index % lineColors.length],
      pointRadius: 2,
    }));

    const chartData = {
      datasets: datasets,
    };

    setData(chartData);
  }, [query.data]);

  return (
    <>
      {!data && <Spinner />}
      {data && <Chart data={data} options={options} type="scatter" />}
    </>
  );
};

export default FirstBredEventChart;
