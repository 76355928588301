// Copyright © 2023 CATTLEytics Inc.

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useInjection } from 'inversify-react';
import React, { CSSProperties, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../../types';
import { AnimalTestDay } from '../../../common/entities/animalTestDay';
import { Sort } from '../../../common/enums';
import AnimalTestDayService from '../../../common/services/animalTestDayService';
import SettingsContext from '../../../common/store/setings-context';
import { getCssVariable } from '../../../common/utilities';
import { QueryKey } from '../../../shared';

interface Props {
  animalId: number;
  style?: CSSProperties;
}

/**
 * Component to display a chart of animal total milk test data
 */
const AnimalChartMilkCombined = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalTestDayService = useInjection<AnimalTestDayService>(TYPES.animalTestDayService);

  const settings = useContext(SettingsContext);

  const limit = '100';
  const offset = '0';
  const sortDirection = Sort.Ascending;
  const query = useQuery<AnimalTestDay[]>(
    [QueryKey.TestData, props.animalId, limit, offset, sortDirection],
    () =>
      animalTestDayService.getAnimalTestDays({
        limit: limit,
        offset: offset,
        animalId: String(props.animalId),
        sortDirection: sortDirection,
      }),
    {
      keepPreviousData: true,
    },
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        type: 'time' as const,
        time: {
          bounds: 'data',
          tooltipFormat: settings.dateFormat,
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Milk Quantity',
        },
      },
      y1: {
        beginAtZero: true,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        title: {
          display: true,
          text: 'Percentage',
        },
      },
    },
  };

  if (!query.data) {
    return <>{t('No data')}</>;
  }

  const values = query.data.filter(
    (item) => item.milkProperties.quantity && item.milkProperties.energyCorrectedQuantity,
  );

  const data = {
    datasets: [
      {
        yAxisID: 'y',
        xAxisID: 'x',
        label: t('Quantity'),
        data: values.map((map) => ({ x: map.testDate, y: map.milkProperties.quantity })),
        borderColor: getCssVariable('--bs-info'),
        backgroundColor: getCssVariable('--bs-info'),
      },
      {
        yAxisID: 'y1',
        xAxisID: 'x',
        label: t('Protein'),
        data: values.map((map) => ({ x: map.testDate, y: map.milkProperties.proteinPct })),
        borderColor: getCssVariable('--bs-warning'),
        backgroundColor: getCssVariable('--bs-warning'),
      },
      {
        yAxisID: 'y1',
        xAxisID: 'x',
        label: t('Fat'),
        data: values.map((map) => ({ x: map.testDate, y: map.milkProperties.fatPct })),
        borderColor: getCssVariable('--bs-danger'),
        backgroundColor: getCssVariable('--bs-danger'),
      },
    ],
  };

  return <Line data={data} options={options} style={props.style} />;
};

export default AnimalChartMilkCombined;
