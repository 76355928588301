// Copyright © 2023 CATTLEytics Inc.

import { sql } from '@codemirror/lang-sql';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { parseISO } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import AlertError from '../../common/components/AlertError';
import AnimalMilkingStatusBadge from '../../common/components/AnimalMilkingStatusBadge';
import AnimalReproductionStatusBadge from '../../common/components/AnimalReproductionStatusBadge';
import Button from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import LactationNumberBadge from '../../common/components/LactationNumberBadge';
import Page from '../../common/components/Page';
import { SettingsLocalization } from '../../common/entities/setting';
import { Sort } from '../../common/enums';
import { CqlQueryResponse } from '../../common/services/cqlService';
import SettingsContext from '../../common/store/setings-context';
import {
  api,
  formatDateAbsolute,
  formatDateRelative,
  IconRun,
  IconTag,
} from '../../common/utilities';
import { camelToTitleCase } from '../../common/utilities/camelCase';
import { AnimalIndex, ApiResourceV1, HttpMethod, QueryKey } from '../../shared';
import { CQL, darkTheme, formatCode, lightTheme } from './ReportsCodeMirror';
import { ReportsInstructions } from './ReportsInstructions';

/**
 * Dates returned from OpenSearch are UTC, but sometimes aren't set correctly.
 * eg, 'birthDate: "2012-08-15T00:00:00.000Z"' is most likely in EST/EDT if the timeZone is set there, not in UTC.
 * eg, 'calvingDate: "2023-10-29T04:00:00.000Z"' were actually EDT (-4).
 */
function customReportFormatDate(
  input: Date | string,
  settings: SettingsLocalization,
  hideTime = true,
): string {
  if (!input) {
    return '';
  }

  const date =
    settings.preferredDateTimeStyle === 'absolute'
      ? formatDateAbsolute(input, settings.dateFormat)
      : formatDateRelative(typeof input === 'string' ? parseISO(input) : input, hideTime);

  return date;
}

interface Props {
  className?: string;
}

interface RouteParams {
  /**
   * The URL encoded query parameter we will use to generate a custom report
   */
  queryEncoded: string;
}

/**
 * Routable component to display custom reports.
 */
const ReportsCustomPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const settings = useContext(SettingsContext);

  const { queryEncoded } = useParams<RouteParams>();
  const cqlQueryParam = decodeURIComponent(queryEncoded);

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('primaryTag');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);

  const refs = React.useRef<ReactCodeMirrorRef>({});

  const [cqlQuery, setCqlQuery] = useState<string>(formatCode(cqlQueryParam));
  const [runQuery, setRunQuery] = useState<string>(cqlQuery);

  const query = useQuery<CqlQueryResponse<AnimalIndex> | undefined>(
    [QueryKey.CqlQueries, runQuery, limit, offset, sortField, sortDirection],
    () =>
      api(HttpMethod.Post, ApiResourceV1.CqlQueries, {
        body: {
          query: runQuery,
          limit: limit,
          offset: offset,
        },
      }),
    {
      keepPreviousData: false,
      staleTime: 3000,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );

  const queryKeys = useQuery([QueryKey.CqlQueriesMeta], () =>
    api('GET', `/v1/cql-queries-meta`, {}),
  );

  const headers: DataTableHeader[] = useMemo(
    () =>
      query.data
        ? query.data.columns
            .filter((col) => col !== 'id')
            .map((col: string) => ({
              name: col,
              label: camelToTitleCase(col),
              code: col,
            }))
        : [],
    [query.data],
  );

  const data: DataTableRow[] = useMemo(
    () =>
      !query.data
        ? []
        : query.data.data.map((row) => {
            const datum: Record<string, string> = {};
            const columns = query.data?.columns ?? [];
            for (const col of columns) {
              datum[col] = row[col] ? String(row[col]) : '';
            }
            return {
              ...datum,
              ...{
                id: String(row.id),
                daysInMilk: String(row.daysInMilk ?? '-'),
                lactationGroup: row.lactationGroup ? (
                  <LactationNumberBadge lactationNumber={row.lactationNumber} />
                ) : (
                  '-'
                ),
                pen: row.pen,
                primaryTag: row.primaryTag ? (
                  <>
                    <IconTag className={'me-1'} style={{ fontSize: '12px' }} />
                    {row.primaryTag}
                  </>
                ) : (
                  '-'
                ),
                name: row.name,
                milkingStatus: <AnimalMilkingStatusBadge status={row.milkingStatus} />,
                reproductionStatus: (
                  <AnimalReproductionStatusBadge status={row.reproductionStatus} />
                ),
                status: row.status,
                birthDate: customReportFormatDate(row.birthDate, settings),
                calvingDate: customReportFormatDate(row.calvingDate, settings),
                calvingDueDate: customReportFormatDate((row as any).calvingDueDate, settings),
                date: customReportFormatDate((row as any).date, settings, false),
              },
            };
          }),
    [query.data, settings],
  );

  const myTheme = document.documentElement.dataset.bsTheme === 'light' ? lightTheme : darkTheme;
  return (
    <Page className={props.className} title={t('reportsCustomPage|title')}>
      <CodeMirror
        basicSetup={{
          lineNumbers: true,
          indentOnInput: false,
          defaultKeymap: true,
        }}
        className={'code-mirror'}
        extensions={[
          sql({
            dialect: CQL,
            upperCaseKeywords: true,
            // TODO: change schema keys based on what the use is typing (index indicated)
            schema: queryKeys?.data?.animals.autocomplete,
          }),
        ]}
        onChange={(value: string): void => {
          setCqlQuery(value);
        }}
        ref={refs}
        theme={myTheme}
        value={cqlQuery}
      />
      <Button
        busy={query.isFetching}
        className={'mt-3'}
        disabled={query.isFetching}
        icon={IconRun}
        onClick={async (): Promise<void> => {
          const code = formatCode(cqlQuery);
          history.replace(`/reports/custom/${encodeURIComponent(code.replace(/\n/g, ' '))}`);
          setCqlQuery(code);
          setOffset(0);

          if (code === runQuery) {
            await query.refetch();
          } else {
            setRunQuery(code);
          }
        }}
      >
        {t('reportsCustomPage|runButton')}
      </Button>

      {query.isError && <AlertError message={(query.error as Error).message} />}
      {headers.length > 0 && !query.isError && (
        <DataTable
          className={'mt-3'}
          data={data}
          headers={headers}
          isLoading={query.isLoading}
          isPreviousData={query.isPreviousData}
          limit={limit}
          messageNoData={t('reportsCustomPage|tableNoDataMessage')}
          offset={offset}
          onLimitChange={(newLimit): void => setLimit(newLimit)}
          onOffsetChange={(newOffset): void => setOffset(newOffset)}
          onRowClick={(row): void => {
            if (!row.id) {
              return undefined;
            }
            if (cqlQuery.search(/^EVENTS/i) >= 0) {
              history.push(`/events/${row.id}`);
            } else {
              history.push(`/animals/${row.id}`);
            }
          }}
          onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
          onSortFieldChange={(newSortField): void => setSortField(newSortField)}
          sortDirection={sortDirection}
          sortField={sortField}
        />
      )}
      <ReportsInstructions />
    </Page>
  );
};

export default ReportsCustomPage;
