// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Badge,
  Button as ButtonBootstrap,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Popover,
  Stack,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Clamp from 'react-multiline-clamp';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import DateTime from '../../common/components/DateTime';
import NotificationService from '../../common/services/notificationService';
import { IconListCheck, IconNotifications } from '../../common/utilities';
import { Notification, QueryKey } from '../../shared';
import NotificationModal from './NotificationModal';

/**
 * Notifications for the nav bar.
 */
const NavBarNotifications = (): JSX.Element => {
  const { t } = useTranslation();

  const notificationService = useInjection<NotificationService>(TYPES.notificationService);
  const [selectedNotification, setSelectedNotification] = useState<Notification | undefined>();
  const [showNotifications, setShowNotifications] = useState(false);
  const queryClient = useQueryClient();
  const query = useQuery<Notification[]>(
    QueryKey.Notifications,
    () =>
      notificationService.list({
        limit: '6',
      }),
    { refetchInterval: 60000 },
  );

  const invalidateNotifications = useCallback(
    async () => await queryClient.invalidateQueries(QueryKey.Notifications),
    [queryClient],
  );

  const markAllReadRequest = useMutation(() => notificationService.patchAll({ read: true }), {
    onSuccess: invalidateNotifications,
  });

  const markReadRequest = useMutation(
    (id: Notification['id']) => notificationService.patch(id, { read: true }),
    { onSuccess: invalidateNotifications },
  );

  const unreadNotificationCount = useMemo(
    () => (query.data ? query.data.filter((notification) => !notification.read).length : 0),
    [query.data],
  );

  const onNotificationClick = useCallback(
    async (notification: Notification) => {
      if (!notification.read) {
        await markReadRequest.mutateAsync(notification.id);
      }
      setSelectedNotification(notification);
      setShowNotifications(false);
    },
    [setShowNotifications, markReadRequest],
  );

  const popover = (
    <Popover arrowProps={{ style: {} }} id="popover-basic">
      <Popover.Header>
        <Stack direction="horizontal">
          <h3 className="m-0">{t('Notifications')}</h3>
          {unreadNotificationCount !== 0 && (
            <IconListCheck
              className="ms-auto cursor-pointer"
              onClick={(): void => {
                markAllReadRequest.mutateAsync();
              }}
              title={t('Mark all as read')}
            />
          )}
        </Stack>
      </Popover.Header>
      <Popover.Body className="p-0">
        <ListGroup
          style={{ borderBottomRightRadius: '2rem', borderBottomLeftRadius: '2rem' }}
          variant="flush"
        >
          {query.data &&
            query.data.map((notification) => (
              <ListGroupItem
                action
                key={notification.id}
                onClick={async (): Promise<void> => await onNotificationClick(notification)}
              >
                <div className={'d-flex'}>
                  {!notification.read && (
                    <Badge bg="primary" className="me-2" pill>
                      new
                    </Badge>
                  )}
                  <DateTime date={notification.createdDate} />
                </div>
                {!!notification.heading && (
                  <>
                    <strong>{notification.heading}</strong>
                    <br />
                  </>
                )}

                <Clamp lines={4}>{notification.body}</Clamp>
              </ListGroupItem>
            ))}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        defaultShow={false}
        onToggle={(nextShow): void => setShowNotifications(nextShow)}
        overlay={popover}
        placement="bottom"
        rootClose
        show={showNotifications}
        trigger="click"
      >
        {({ ref, ...triggerHandler }): ReactNode => (
          <span ref={ref} {...triggerHandler} style={{ position: 'relative' }}>
            <ButtonBootstrap className={'rounded-circle ms-2 me-sm-2'} variant={'link'}>
              <IconNotifications size={'1rem'} />{' '}
              {unreadNotificationCount > 0 && (
                <Badge
                  bg={'danger'}
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    margin: 0,
                    marginTop: '-4px',
                    marginRight: '-4px',
                    height: '20px',
                    width: '20px',
                    right: 0,
                    fontSize: '13px',
                    zIndex: 1000,
                    bottom: 0,
                  }}
                >
                  {unreadNotificationCount}
                </Badge>
              )}
            </ButtonBootstrap>
          </span>
        )}
      </OverlayTrigger>
      {selectedNotification && (
        <NotificationModal
          notification={selectedNotification}
          onClose={(): void => setSelectedNotification(undefined)}
        />
      )}
    </>
  );
};

export default NavBarNotifications;
