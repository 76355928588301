// Copyright © 2023 CATTLEytics Inc.

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useContext } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GiBiceps, GiFat, GiMilkCarton } from 'react-icons/gi';
import { MdChildFriendly } from 'react-icons/md';
import { useQuery } from 'react-query';

import ChartLineSimpleTiny from '../../common/components/ChartLineSimpleTiny';
import Spinner from '../../common/components/Spinner';
import ReportHerdInfo from '../../common/entities/reportHerdInfo';
import { SettingsLocalization } from '../../common/entities/setting';
import SettingsContext from '../../common/store/setings-context';
import { api } from '../../common/utilities';
import { decimateArray } from '../../common/utilities/filter';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared';

interface Props {
  className?: string;
}

/**
 * Shows animals birth and test data
 */
const Stats = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const settings = useContext<SettingsLocalization>(SettingsContext);
  const query = useQuery<ReportHerdInfo | undefined>(
    [QueryKey.Reports, 'herd-info'],
    () => api<ReportHerdInfo>(HttpMethod.Get, `${ApiResourceV1.Reports}/herd-info`),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: false,
    },
  );

  if (query.isLoading) {
    return <Spinner />;
  }

  if (!query.data) {
    return <div>No Data</div>;
  }

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const averageMilkLast5Years = new Intl.NumberFormat(settings.language, {
    maximumFractionDigits: 2,
  }).format(query.data.averageMilkLast5Years);
  const averageFatLast5Years = new Intl.NumberFormat(settings.language, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(query.data.averageFatLast5Years);
  const averageProteinLast5Years = new Intl.NumberFormat(settings.language, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(query.data.averageProteinLast5Years);

  return (
    <section className={props.className}>
      <Card.Body style={{ position: 'relative' }}>
        {/*Coming Soon Overlay element*/}
        {/*<div className={'coming-soon-overlay'}>Coming Soon</div>*/}
        <ListGroup>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 icon">
              <GiMilkCarton />
            </div>
            <div className="ms-2 me-auto">
              <div className="fw-bold">{t('Milk')}</div>
              {averageMilkLast5Years} lbs
            </div>
            <div style={{ width: '130px', height: '50px' }}>
              <ChartLineSimpleTiny
                labels={decimateArray(
                  query.data.averageMilkLast5YearsByMonth.map((item) => item.label),
                  1,
                )}
                values={decimateArray(
                  query.data.averageMilkLast5YearsByMonth.map((item) => String(item.value)),
                  1,
                )}
              />
            </div>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 icon">
              <GiFat />
            </div>
            <div className="ms-2 me-auto">
              <div className="fw-bold">Fat</div>
              {averageFatLast5Years}
            </div>
            <div style={{ width: '130px', height: '50px' }}>
              <ChartLineSimpleTiny
                labels={decimateArray(
                  query.data.averageFatLast5YearsByMonth.map((item) => item.label),
                  1,
                )}
                values={decimateArray(
                  query.data.averageFatLast5YearsByMonth.map((item) => String(item.value * 100)),
                  1,
                )}
              />
            </div>
            {/*<FontAwesomeIcon icon={faChartLine} style={{ color: 'red' }} className="mx-1" />*/}
          </ListGroup.Item>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 icon">
              <GiBiceps />
            </div>
            <div className="ms-2 me-auto">
              <div className="fw-bold">Protein</div>
              {averageProteinLast5Years}
            </div>
            <div className={''} style={{ width: '130px', height: '50px' }}>
              <ChartLineSimpleTiny
                labels={decimateArray(
                  query.data.averageProteinLast5YearsByMonth.map((item) => item.label),
                  1,
                )}
                values={decimateArray(
                  query.data.averageProteinLast5YearsByMonth.map((item) =>
                    String(item.value * 100),
                  ),
                  1,
                )}
              />
            </div>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 icon">
              <MdChildFriendly />
            </div>
            <div className="ms-2 me-auto">
              <div className="fw-bold">Births</div>
              --
            </div>
            <div className={''} style={{ width: '130px', height: '50px' }}>
              {/*<HerdChartBirthsLastYear />*/}
              <ChartLineSimpleTiny
                labels={decimateArray(
                  query.data.totalBirthsLast5YearsByMonth.map((item) => item.label),
                  4,
                )}
                values={decimateArray(
                  query.data.totalBirthsLast5YearsByMonth.map((item) => String(item.value)),
                  4,
                )}
              />
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </section>
  );
};

export default Stats;
