// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useContext, useEffect, useState } from 'react';
import { Form, FormGroup, InputGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import RoleSelect from '../../common/components/RoleSelect';
import SiteSelect from '../../common/components/SiteSelect';
import User from '../../common/entities/user';
import RoleService from '../../common/services/roleService';
import UserService from '../../common/services/userService';
import AuthContext from '../../common/store/auth-context';
import { IconCopyToClipboard, IconGenerate, IconSuperAdmin } from '../../common/utilities';
import { isSiteAdmin } from '../../common/utilities';
import Logger from '../../logger/logger';
import { RoleEnum } from '../../shared';
import { QueryKey } from '../../shared/enums';

// 1 upper, 1 lower, 1 number, 8 chars or more
const generatePassword = (passwordLength = 8): string => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const lowers = 'abcdefghijklmnopqrstuvwxyz';
  const uppers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let password = '';

  // ensure password generated has at least 1 of each required character
  for (let i = 0; i <= passwordLength; i++) {
    if (i === 0) {
      const randomNumber = Math.floor(Math.random() * numbers.length);
      password += numbers.substring(randomNumber, randomNumber + 1);
    } else if (i === 1) {
      const randomNumber = Math.floor(Math.random() * lowers.length);
      password += lowers.substring(randomNumber, randomNumber + 1);
    } else if (i === 2) {
      const randomNumber = Math.floor(Math.random() * uppers.length);
      password += uppers.substring(randomNumber, randomNumber + 1);
    } else {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
  }
  return password;
};

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit an animal user.
 */
const UserModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const userService = useInjection<UserService>(TYPES.userService);
  const roleService = useInjection<RoleService>(TYPES.roleService);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [data] = useState<Record<string, string | boolean>>({
    firstName: '',
    lastName: '',
    email: '',
    password: generatePassword(),
    isSuperAdmin: false,
  });

  const auth = useContext(AuthContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>(generatePassword());
  const [passwordCheck, setPasswordCheck] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [role, setRole] = useState<RoleEnum>(RoleEnum.User);
  const [siteId, setSiteId] = useState<number | undefined>(auth.siteId);

  const query = useQuery<User | undefined>(
    [QueryKey.Users, props.editId],
    () => userService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (query.data) {
      setPassword('');
      setFirstName(query.data.firstName);
      setLastName(query.data.lastName);
      setEmail(query.data.email);
      setIsSuperAdmin(query.data.isSuperAdmin);
    }
  }, [query.data]);

  const mutation = useMutation(
    async () => {
      const newData: Record<string, string | boolean> = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        isSuperAdmin: isSuperAdmin,
        siteId: siteId ? siteId.toString() : '',
      };
      if (password === '') {
        delete newData.password;
      }
      let user: User | undefined;
      if (props.editId) {
        user = await userService.patch(props.editId, newData);
      } else {
        if (siteId) {
          user = await userService.post(newData);
        }
        if (user) {
          if (role) {
            await roleService.post({
              role: role,
              userId: user.id,
              ...(siteId !== undefined && { siteId }),
            });
          }
        }
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.Users);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      props.onClose();
    } catch (err) {
      logger.error(`User could not be saved.`, err, data);
      setErrorMessage((err as Error).message);
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formLastName">
        <Form.Label>
          {t('First Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'firstName'}
          onChange={(e): void => setFirstName(e.target.value)}
          placeholder={t('Example: {{value}}', { value: 'John' })}
          required
          type={'text'}
          value={firstName}
        />
        <Form.Text className={'text-muted'}>The first name of this user.</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formFirstName">
        <Form.Label>
          {t('Last Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'lastName'}
          onChange={(e): void => setLastName(e.target.value)}
          placeholder={t('Example: {{value}}', { value: 'Smith' })}
          required
          type={'text'}
          value={lastName}
        />
        <Form.Text className={'text-muted'}>The last name of this user.</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formEmail">
        <Form.Label>
          {t('Email')} <Required />
        </Form.Label>
        <Form.Control
          name={'email'}
          onChange={(e): void => setEmail(e.target.value)}
          placeholder={t('Example: {{value}}', { value: 'jsmith@example.com' })}
          required
          type={'email'}
          value={email}
        />
        <Form.Text className={'text-muted'}>{t('userModal|userEmailText')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('userModal|requiredValidEmailInvalidFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formPassword">
        <Form.Label>
          {t('Password')} <Required />
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            autoComplete="off"
            disabled={false}
            isInvalid={passwordCheck}
            name={'password'}
            onChange={(e): void => {
              setPassword(e.target.value);
              // regex to check if password contains 1 upper, 1 lower, and 1 number
              if (
                e.target.value.match(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/) &&
                e.target.value.length >= 8
              ) {
                setPasswordCheck(false);
              } else {
                setPasswordCheck(true);
              }
            }}
            placeholder={t('Use existing password')}
            required={!props.editId}
            value={password}
          />
          <CopyToClipboard text={password}>
            <Button
              icon={IconCopyToClipboard}
              label={'Copy'}
              type={'button'}
              variant="outline-primary"
            />
          </CopyToClipboard>
          <Button
            icon={IconGenerate}
            label={'Generate Password'}
            onClick={(): void => setPassword(generatePassword())}
            type={'button'}
            variant="outline-primary"
          />
          <Form.Control.Feedback type={'invalid'}>
            {t(
              'Password must be at least 8 characters long and contain at least 1 uppercase, 1 lowercase, and 1 number',
            )}
          </Form.Control.Feedback>
        </InputGroup>
        <Form.Text className={'text-muted'}>Set a password for this user.</Form.Text>
      </FormGroup>
      {!props.editId && (
        <>
          {!isSiteAdmin(auth) && (
            <FormGroup className="form-group mb-3" controlId="formSite">
              <Form.Label>{t('Choose an initial site')}</Form.Label>
              <SiteSelect
                onChange={(e): void => setSiteId(Number(e.target.value))}
                required
                value={String(siteId)}
              />
              <Form.Text className={'text-muted'}>
                {t('The site to assign to this user. Additional sites can be added later.')}
              </Form.Text>
              <Form.Control.Feedback type={'invalid'}>
                {t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
            </FormGroup>
          )}
          <FormGroup className="form-group mb-3" controlId="formRole">
            {!auth.isSuperAdmin ? (
              <>
                <Form.Label>{t('Choose a role')}</Form.Label>
                <RoleSelect onChange={(role): void => setRole(role)} required value={role} />
                <Form.Text className={'text-muted'}>{t('userModal|roleSelectionText')}</Form.Text>
              </>
            ) : (
              <>
                <Form.Label>{t('Choose a role for this user in the initial site.')}</Form.Label>
                <RoleSelect onChange={(role): void => setRole(role)} required value={role} />
                <Form.Text className={'text-muted'}>
                  {t(
                    'The role to assign to this user. A user can have a different role in each site they belong to.',
                  )}
                </Form.Text>
              </>
            )}
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
          </FormGroup>
        </>
      )}
      {auth.isSuperAdmin && (
        <FormGroup className="mb-3" controlId="formLastStep">
          <Form.Label>
            {t('Super Administrator')} <IconSuperAdmin />
          </Form.Label>
          <Form.Check
            checked={isSuperAdmin}
            label={t('Yes, make this user a super administrator')}
            name={'isSuperAdmin'}
            onChange={(e): void => setIsSuperAdmin(e.target.checked)}
            type={'checkbox'}
          />
          <Form.Text className={'text-muted'}>
            {t('This will allow the user to have full access to all sites.')}
          </Form.Text>
        </FormGroup>
      )}

      {!props.editId && (
        <div className="mt-3">
          <Form.Text className={'text-muted'}>{t('userModal|addUserNote')}</Form.Text>
        </div>
      )}

      <AlertErrorForModal message={errorMessage} />

      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? 'Edit' : 'Add'} User`}
      visible={true}
    >
      {query.isError && (
        <AlertError message={`Something unexpected happened while retrieving user.`} />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
    </Modal>
  );
};

export default UserModal;
