// Copyright © 2023 CATTLEytics Inc.
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import calfImage from '../../../assets/img/calf.svg';
import cowImage from '../../../assets/img/cow.svg';
import AnimalModal from '../../animals/components/AnimalModal';
import DairyLogModal from '../../dairy-logs/components/DairyLogModal';
import DiagnosisRegimeModal from '../../diagnoses/components/DiagnosisRegimeModal';
import EventModal from '../../events/components/EventModal';
import ManageListEditModal from '../../lists/components/ManageListEditModal';
import NoteModal from '../../notes/components/NoteModal';
import PenModal from '../../pens/components/PenModal';
import ProductModal from '../../products/components/ProductModal';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared';
import { TaskModal } from '../../tasks/components/TaskModal';
import AnimalEventType from '../entities/animalEventType';
import { AnimalEventType as AnimalEventTypeEnum } from '../enums/animalEventType';
import { useIsAuthUserSuperAdmin } from '../hooks';
import { api, IconEvent, IconGeneral, IconList, IconTreatment } from '../utilities';
import Button, { ButtonVariant } from './Button';
import Modal from './Modal';

interface Props {
  /**
   * Callback when modal close button is clicked
   */
  onClose: () => void;
}

const NewButtonModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [animalModalVisible, setAnimalModalVisible] = useState<boolean>(false);
  const [eventModalVisible, setEventModalVisible] = useState<boolean>(false);
  const [eventType, setEventType] = useState<AnimalEventType>();
  const [eventTypes, setEventTypes] = useState<AnimalEventType[]>([]);
  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [dairyLogModalVisible, setDairyLogModalVisible] = useState<boolean>(false);
  const [listColorModalVisible, setListColorModalVisible] = useState<boolean>(false);
  const [listDiagnosisModalVisible, setListDiagnosisModalVisible] = useState<boolean>(false);
  const [listDiagnosisRegimeModalVisible, setListDiagnosisRegimeModalVisible] =
    useState<boolean>(false);
  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);
  const [listBirthStatusModalVisible, setListBirthStatusModalVisible] = useState<boolean>(false);
  const [listBreedModalVisible, setListBreedModalVisible] = useState<boolean>(false);
  const [listEventTypeModalVisible, setListEventTypeModalVisible] = useState<boolean>(false);
  const [listGenderModalVisible, setListGenderModalVisible] = useState<boolean>(false);
  const [listReferenceTypeModalVisible, setListReferenceTypeModalVisible] =
    useState<boolean>(false);
  const [listReproStatusModalVisible, setListReproStatusModalVisible] = useState<boolean>(false);
  const [listProductModalVisible, setListProductModalVisible] = useState<boolean>(false);
  const [listProductClassModalVisible, setListProductClassModalVisible] = useState<boolean>(false);
  const [listProductTypeModalVisible, setListProductTypeModalVisible] = useState<boolean>(false);

  const isSuperAdmin = useIsAuthUserSuperAdmin();

  const history = useHistory();

  const query = useQuery<AnimalEventType[]>([QueryKey.AnimalEventTypes], () =>
    api<AnimalEventType[]>(HttpMethod.Get, ApiResourceV1.AnimalEventTypes, {
      params: { limit: '1000', sortDirection: 'asc', sortField: 'id' },
    }),
  );

  useEffect(() => {
    if (!query.data) return;
    setEventTypes(query.data);
  }, [query.data]);

  const getEventTypeById = (eventTypeId: AnimalEventTypeEnum): AnimalEventType | undefined => {
    const type = eventTypes.filter((type) => type.id === eventTypeId);
    if (type.length > 0) {
      return type[0];
    }
    return undefined;
  };

  const showEventModal = (eventType?: AnimalEventType): void => {
    if (eventType) {
      setEventType(eventType);
    }
    setEventModalVisible(true);
  };

  return (
    <Modal onClose={props.onClose} size={'lg'} title={'New'} visible={true}>
      <div className={'new-modal show-grid'}>
        <Row md={3}>
          <Col>
            <Card body className={'text-center'} style={{ height: '160px' }}>
              <div
                className={'mb-2 d-flex justify-content-center align-items-center'}
                style={{ height: '75px' }}
              >
                <img
                  alt={t('New Animal')}
                  src={cowImage}
                  style={{
                    margin: '1rem auto',
                    filter:
                      'invert(31%) sepia(33%) saturate(353%) hue-rotate(163deg) brightness(90%) contrast(90%)',
                    width: '100px',
                  }}
                />
              </div>
              <Button
                className={'w-100 stretched-link'}
                onClick={(): void => setAnimalModalVisible(true)}
                variant={ButtonVariant.OutlinePrimary}
              >
                {t('New Animal')}
              </Button>
            </Card>
          </Col>
          <Col>
            <Card body className={'text-center'}>
              <div
                className={'mb-2 d-flex justify-content-center align-items-center'}
                style={{ height: '75px' }}
              >
                <img
                  alt={t('New Calf')}
                  src={calfImage}
                  style={{
                    filter:
                      'invert(31%) sepia(33%) saturate(353%) hue-rotate(163deg) brightness(90%) contrast(90%)',
                    height: '45px',
                  }}
                />
              </div>
              <Button
                className={'w-100 stretched-link'}
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Calving))}
                variant={ButtonVariant.OutlinePrimary}
              >
                {t('New Calf')}
              </Button>
            </Card>
          </Col>
          <Col>
            <Card body className={'text-center'}>
              <div
                className={'mb-2 d-flex justify-content-center align-items-center'}
                style={{ height: '75px' }}
              >
                <IconTreatment className={'text-primary'} style={{ fontSize: '35px' }} />
              </div>
              <Button
                className={'w-100 stretched-link'}
                onClick={(): void =>
                  showEventModal(getEventTypeById(AnimalEventTypeEnum.Diagnosis))
                }
                variant={ButtonVariant.OutlinePrimary}
              >
                {t('New Treatment')}
              </Button>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="mt-3 mb-3 d-flex align-items-center">
              <div className={'rounded-circle me-2'}>
                <IconGeneral style={{ textAlign: 'center', fontSize: '1.2rem' }} />
              </div>
              <strong>{t('General')}</strong>
            </div>
            <ListGroup>
              <ListGroup.Item action onClick={(): void => setAnimalModalVisible(true)}>
                {t('Animal')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Calving))}
              >
                {t('Calf')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setDairyLogModalVisible(true)}>
                {t('Log')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setNoteModalVisible(true)}>
                {t('Note')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setCreateModalVisible(true)}>
                {t('Pen')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setTaskModalVisible(true)}>
                {t('Task')}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <div className="my-3 d-flex align-items-center">
              <div className={'rounded-circle me-2'}>
                <IconEvent style={{ textAlign: 'center', fontSize: '1.2rem' }} />
              </div>
              <strong>{t('Events')}</strong>
            </div>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Arrival))}
              >
                {t('Arrival')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Bred))}
              >
                {t('Breeding')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Calving))}
              >
                {t('Calving')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.DriedOff))}
              >
                {t('Dried Off')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Fresh))}
              >
                {t('Fresh')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Move))}
              >
                {t('Move')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Sold))}
              >
                {t('Sold')}
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={(): void =>
                  showEventModal(getEventTypeById(AnimalEventTypeEnum.Diagnosis))
                }
              >
                {t('Treatment')}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <div className="mt-3 mb-3 d-flex align-items-center">
              <div className={'rounded-circle me-2'}>
                <IconList style={{ textAlign: 'center', fontSize: '1.2rem' }} />
              </div>
              <strong>{t('Lists')}</strong>
            </div>
            <ListGroup>
              <ListGroup.Item action onClick={(): void => setListBirthStatusModalVisible(true)}>
                {t('Birth Statuses')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListBreedModalVisible(true)}>
                {t('Breed')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListColorModalVisible(true)}>
                {t('Colour')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListDiagnosisModalVisible(true)}>
                {t('Diagnosis')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListDiagnosisRegimeModalVisible(true)}>
                {t('Diagnosis Regime')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListEventTypeModalVisible(true)}>
                {t('Event Type')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListGenderModalVisible(true)}>
                {t('Gender')}
              </ListGroup.Item>
              {isSuperAdmin && (
                <>
                  <ListGroup.Item action onClick={(): void => setListProductModalVisible(true)}>
                    {t('Product')}
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    onClick={(): void => setListProductClassModalVisible(true)}
                  >
                    {t('Product Class')}
                  </ListGroup.Item>
                  <ListGroup.Item action onClick={(): void => setListProductTypeModalVisible(true)}>
                    {t('Product Type')}
                  </ListGroup.Item>
                </>
              )}
              <ListGroup.Item action onClick={(): void => setListReferenceTypeModalVisible(true)}>
                {t('Reference Type')}
              </ListGroup.Item>
              <ListGroup.Item action onClick={(): void => setListReproStatusModalVisible(true)}>
                {t('Reproduction Status')}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </div>

      {animalModalVisible ? (
        <AnimalModal
          onClose={(): void => {
            setAnimalModalVisible(false);
            props.onClose();
          }}
        />
      ) : null}
      {eventModalVisible && (
        <EventModal
          defaultEventType={eventType}
          onClose={(): void => {
            setEventModalVisible(false);
            props.onClose();
          }}
          onSave={(ae): void => {
            if (ae && ae.activeTreatmentId) {
              history.push(`/active-treatments/${ae.activeTreatmentId}`);
            } else if (ae && ae.length > 0 && ae[0].animalId) {
              history.push(`/animals/${ae[0].animalId}`);
            }
            props.onClose();
          }}
        />
      )}

      {noteModalVisible ? (
        <NoteModal
          onClose={(): void => {
            setNoteModalVisible(false);
            props.onClose();
          }}
        />
      ) : null}

      {createModalVisible ? (
        <PenModal
          onClose={(): void => {
            setCreateModalVisible(false);
            props.onClose();
          }}
        />
      ) : null}

      {dairyLogModalVisible ? (
        <DairyLogModal
          onClose={(): void => {
            setDairyLogModalVisible(false);
            props.onClose();
          }}
        />
      ) : null}

      {listColorModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.AnimalColors}
          onClose={(): void => {
            setListColorModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.AnimalColors}
          typeLabel={'animal colour'}
          typeName={'animalColor'}
        />
      ) : null}
      {listBirthStatusModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.BirthStatuses}
          onClose={(): void => {
            setListBirthStatusModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.BirthStatuses}
          typeLabel={'birth status'}
          typeName={'birthStatus'}
        />
      ) : null}
      {listBreedModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.Breeds}
          onClose={(): void => {
            setListBreedModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.Breeds}
          typeLabel={'breed'}
          typeName={'breed'}
        />
      ) : null}
      {listDiagnosisModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.Diagnoses}
          onClose={(): void => {
            setListDiagnosisModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.Diagnoses}
          typeLabel={'diagnosis'}
          typeName={'diagnosis'}
        />
      ) : null}
      {listEventTypeModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.AnimalEventTypes}
          onClose={(): void => {
            setListEventTypeModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.AnimalEventTypes}
          typeLabel={'event type'}
          typeName={'animalEventType'}
        />
      ) : null}
      {listGenderModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.Genders}
          onClose={(): void => {
            setListGenderModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.Genders}
          typeLabel={'gender'}
          typeName={'gender'}
        />
      ) : null}
      {listReferenceTypeModalVisible ? (
        <ManageListEditModal
          apiResource={ApiResourceV1.AnimalReferenceTypes}
          onClose={(): void => {
            setListReferenceTypeModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.AnimalReferenceTypes}
          typeLabel={'reference'}
          typeName={'animalReferenceType'}
        />
      ) : null}
      {listReproStatusModalVisible && (
        <ManageListEditModal
          apiResource={ApiResourceV1.ReproStatuses}
          onClose={(): void => {
            setListReproStatusModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.ReproStatuses}
          typeLabel={'reproduction'}
          typeName={'reproStatus'}
        />
      )}
      {listProductModalVisible && (
        <ProductModal
          onClose={(): void => {
            setListProductModalVisible(false);
            props.onClose();
          }}
        />
      )}
      {listDiagnosisRegimeModalVisible && (
        <DiagnosisRegimeModal
          onClose={(): void => setListDiagnosisRegimeModalVisible(false)}
          onSave={(dr): void => {
            setListDiagnosisRegimeModalVisible(false);
            history.push(`/diagnosis-regimes/${dr.id}`);
            props.onClose();
          }}
        />
      )}
      {listProductClassModalVisible && (
        <ManageListEditModal
          apiResource={ApiResourceV1.ProductClasses}
          onClose={(): void => {
            setListProductClassModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.ProductClasses}
          typeLabel={'product class'}
          typeName={'productClass'}
        />
      )}
      {listProductTypeModalVisible && (
        <ManageListEditModal
          apiResource={ApiResourceV1.ProductTypes}
          onClose={(): void => {
            setListProductTypeModalVisible(false);
            props.onClose();
          }}
          queryKey={QueryKey.ProductTypes}
          typeLabel={'product type'}
          typeName={'productType'}
        />
      )}
      {taskModalVisible && (
        <TaskModal
          onClose={(): void => setTaskModalVisible(false)}
          onSave={(): void => {
            history.push(`/tasks/all`);
            props.onClose();
          }}
        />
      )}
    </Modal>
  );
};

export default NewButtonModal;
