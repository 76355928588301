// Copyright © 2022 CATTLEytics Inc.

/**
 * Animal Event Type Enumerations
 *
 * Note: these types mapped 1:1 to the system defined rows in animal_events_type table.
 */
export enum AnimalEventType {
  Arrival = 1,
  Sold = 2,
  Pregnant = 3,
  Move = 4,
  Diagnosis = 5,
  Fresh = 6,
  Bred = 7,
  None = 8,
  Abort = 9,
  Died = 10,
  DriedOff = 11,
  Open = 12,
  Calving = 13,
}
