// Copyright © 2023 CATTLEytics Inc.
import { useMemo, VFC } from 'react';

import { Timeline } from '../../../../common/components/timeline';
import { createEntityItem } from '../helpers';
import { useAnimalCardEventFilter } from '../hooks';
import { EventDateItem } from '../types';
import AnimalEventTooltipContent from './AnimalEventTooltipContent';

const dateFormat = 'yyyy-MM-dd';

const nodeDiameter = 25;

type Props = {
  eventDates: EventDateItem[];
};

const AnimalEventsTimeline: VFC<Props> = ({ eventDates }) => {
  const [dateStart] = useAnimalCardEventFilter('dateStart');
  const [dateEnd] = useAnimalCardEventFilter('dateEnd');

  const data = useMemo(
    () => eventDates.map((dateItem) => createEntityItem(dateItem)),
    [eventDates],
  );

  return (
    <div className="timeline-container position-relative w-100">
      <Timeline
        dateEnd={dateEnd}
        dateFormat={dateFormat}
        dateStart={dateStart}
        direction="horizontal"
        entityDates={data}
        entityEventType="hover"
        nodeDiameter={nodeDiameter}
        slots={{
          tooltipContent: AnimalEventTooltipContent,
        }}
      />
    </div>
  );
};

export default AnimalEventsTimeline;
