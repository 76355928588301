// Copyright © 2023 CATTLEytics Inc.
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';

export const NoEventsMessage: VFC = () => {
  const { t } = useTranslation();
  return (
    <div className="py-5 text-center">{t('No events found based on your current filters')}</div>
  );
};
