// Copyright © 2023 CATTLEytics Inc.
import { useCallback, useEffect, useMemo, useState, VFC } from 'react';
import { Form } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import AlertSuccessForModal from '../common/components/AlertSuccessForModal';
import ButtonModalCancel from '../common/components/ButtonModalCancel';
import ButtonModalSave from '../common/components/ButtonModalSave';
import FormFieldWrapper from '../common/components/FormFieldWrapper';
import { getTimeString, getTimeValue } from '../common/components/TimeSelect';
import { api } from '../common/utilities/api';
import { ApiResourceV1, HttpMethod, QueryKey, Shift } from '../shared';
import ColorPicker, { Colors } from './ColorPicker';
import TimeSelectRange from './TimeSelectRange';

export type EditableShift = Pick<
  Shift,
  'endTimeHour' | 'endTimeMinutes' | 'name' | 'startTimeHour' | 'startTimeMinutes'
> & {
  id?: Shift['id'];
};

export const newShift: EditableShift = {
  startTimeHour: 6,
  startTimeMinutes: 0,
  name: '',
  endTimeHour: 12,
  endTimeMinutes: 0,
};

let successTimer: ReturnType<typeof setInterval>;

type Props = {
  onCancel?: () => void;
  onSuccess?: (data?: EditableShift) => void;
  selectedShift: EditableShift;
};

const ManageShiftForm: VFC<Props> = ({ onSuccess, onCancel, selectedShift }) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState(selectedShift.name);
  const [startTimeHour, setStartTimeHour] = useState(selectedShift.startTimeHour);
  const [startTimeMinutes, setStartTimeMinutes] = useState(selectedShift.startTimeMinutes);
  const [endTimeHour, setEndTimeHour] = useState(selectedShift.endTimeHour);
  const [endTimeMinutes, setEndTimeMinutes] = useState(selectedShift.endTimeMinutes);
  const [color, setColor] = useState(Colors[0]);
  const queryClient = useQueryClient();

  const startTime = useMemo(
    () => getTimeString(startTimeHour, startTimeMinutes, t('timeSelect|endOfDay')),
    [startTimeHour, startTimeMinutes, t],
  );
  const endTime = useMemo(
    () => getTimeString(endTimeHour, endTimeMinutes, t('timeSelect|endOfDay')),
    [endTimeHour, endTimeMinutes, t],
  );

  const onEndTimeChange = useCallback(
    (time: Option) => {
      const { hour, minutes } = getTimeValue(time, t('timeSelect|endOfDay'));
      setEndTimeHour(hour);
      setEndTimeMinutes(minutes);
    },
    [t],
  );

  const onStartTimeChange = useCallback(
    (time: Option) => {
      const { hour, minutes } = getTimeValue(time, t('timeSelect|endOfDay'));
      setStartTimeHour(hour);
      setStartTimeMinutes(minutes);
    },
    [t],
  );

  const mutation = useMutation<Shift | undefined>(
    () => {
      let method = HttpMethod.Post;
      let path: string = ApiResourceV1.Shifts;
      if (selectedShift.id) {
        method = HttpMethod.Patch;
        path = `${path}/${selectedShift.id}`;
      }
      return api(method, path, {
        body: {
          name,
          startTimeHour,
          startTimeMinutes,
          endTimeHour,
          endTimeMinutes,
          color,
        },
      });
    },
    {
      onSuccess: async (data) => {
        onSuccess?.(data);
        await queryClient.invalidateQueries([QueryKey.Shifts]);
      },
    },
  );

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      clearInterval(successTimer);
      setSuccessMessage('');

      const form = event.currentTarget;
      const valid = form.checkValidity();

      // mark the form as having its validity checked
      setValidated(true);

      if (!valid) {
        return;
      }

      setErrorMessage('');
      try {
        await mutation.mutateAsync();
        form.classList.remove('was-validated');
        form.reset();
        successTimer = setInterval(() => setSuccessMessage(''), 5000);
      } catch (err) {
        setErrorMessage(t('manageShiftForm|shiftNotSavedError'));
      }
    },
    [t, mutation],
  );

  useEffect(() => {
    const { endTimeHour, endTimeMinutes, name, startTimeHour, startTimeMinutes } = selectedShift;
    clearInterval(successTimer);
    setSuccessMessage('');
    setName(name);
    setStartTimeHour(startTimeHour);
    setStartTimeMinutes(startTimeMinutes);
    setEndTimeHour(endTimeHour);
    setEndTimeMinutes(endTimeMinutes);

    return (): void => clearInterval(successTimer);
  }, [selectedShift]);

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      <div className="h5">{t(selectedShift.id ? 'Edit' : 'Create')}</div>
      <FormFieldWrapper
        controlId="formName"
        invalidFeedback={t('common|fieldRequiredFeedback')}
        label={t('Name')}
      >
        <Form.Control
          minLength={1}
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('Example: Morning Shift')}
          required
          value={name}
        />
      </FormFieldWrapper>

      <TimeSelectRange
        endTime={endTime}
        onEndTimeChange={onEndTimeChange}
        onStartTimeChange={onStartTimeChange}
        required={true}
        startTime={startTime}
        validateEarly={true}
        validated={validated}
      />

      <ColorPicker
        label={t('manageShiftForm|colorPickerLabel')}
        name="color"
        onChange={setColor}
        value={color}
      />

      <AlertErrorForModal message={errorMessage} />
      <AlertSuccessForModal message={successMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel disabled={mutation.isLoading} onClick={onCancel} />
        <ButtonModalSave busy={mutation.isLoading} disabled={mutation.isLoading} />
      </div>
    </Form>
  );
};

export default ManageShiftForm;
