// Copyright © 2024 CATTLEytics Inc.
import { useMemo, VFC } from 'react';
import { ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ButtonModalCancel from '../common/components/ButtonModalCancel';
import Modal from '../common/components/Modal';
import { UserSchedule } from '../shared';
import ManageSchedulesForm from './ManageSchedulesForm';

type Props = Pick<ModalProps, 'onClose'> & {
  schedules?: UserSchedule[];
};

const ManageSchedulesModal: VFC<Props> = ({ onClose, schedules }) => {
  const { t } = useTranslation();
  const title = useMemo(() => t('Manage User Schedule(s)'), [t]);
  if (!schedules?.length) return null;
  return (
    <Modal onClose={onClose} size={'lg'} title={title} visible={true}>
      <ManageSchedulesForm onNoSchedules={onClose} schedules={schedules} />
      <div className="mt-4" />
      <ButtonModalCancel onClick={onClose} />
    </Modal>
  );
};

export default ManageSchedulesModal;
