// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run bin/copy-shared-to-packages.sh
 */

/**
 * Layout Widget Type Enumerations
 */
export enum LayoutWidgetType {
  Notes = 'notes',
  Tasks = 'tasks',
}
