// Copyright © 2023 CATTLEytics Inc.

import convert, { Unit } from 'convert-units';
import { format, parseISO } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../../types';
import AnimalAutocomplete from '../../../animals/components/AnimalAutocomplete';
import AlertErrorForModal from '../../../common/components/AlertErrorForModal';
import Button from '../../../common/components/Button';
import { ButtonVariant } from '../../../common/components/Button';
import ButtonModal from '../../../common/components/ButtonModal';
import DiagnosisRegimeSelect from '../../../common/components/DiagnosisRegimeSelect';
import DiagnosisSelect from '../../../common/components/DiagnosisSelect';
import Required from '../../../common/components/Required';
import AnimalEvent from '../../../common/entities/animalEvent';
import Pen from '../../../common/entities/pen';
import User from '../../../common/entities/user';
import { AnimalEventType as AnimalEventTypeEnum, DecisionTreeAnswers } from '../../../common/enums';
import ActiveTreatmentService, {
  PostPayloadEvent,
} from '../../../common/services/activeTreatmentService';
import UserService from '../../../common/services/userService';
import AuthContext from '../../../common/store/auth-context';
import SettingsContext from '../../../common/store/setings-context';
import { IconSave } from '../../../common/utilities';
import DiagnosisDecisionTree from '../../../diagnoses/components/DiagnosisDecisionTree';
import DiagnosisRegimePreviewModal from '../../../diagnoses/components/DiagnosisRegimePreviewModal';
import { Animal } from '../../../shared';
import { DiagnosisRegimeDetail, TaskStatus } from '../../../shared';
import { QueryKey } from '../../../shared/enums';
import UserAutocomplete from '../../../users/components/UserAutocomplete';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when form is cancelled.
   */
  onCancel?: () => void;

  /**
   * Callback when form has been submitted successfully.
   */
  onSubmitSuccess?: (animalEvent: AnimalEvent) => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;
}

export interface Payload {
  [key: string]: any | undefined;
  animalEventTypeId: number;
  animalIds: number[];
  diagnosisId: number;
  diagnosisRegimeId: number;
  eventDateTime: string;
  notes: string;
  userId: number;
}

const DiagnosisForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const activeTreatmentService = useInjection<ActiveTreatmentService>(TYPES.activeTreatmentService);
  const userService = useInjection<UserService>(TYPES.userService);

  const [showAddDiagnosisModal, setShowAddDiagnosisModal] = useState<boolean>(false);
  const [showAddDiagnosisRegimeModal, setShowAddDiagnosisRegimeModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showDiagnosisRegimePreviewModal, setShowDiagnosisRegimePreviewModal] =
    useState<boolean>(false);

  const [completedEvents, setCompletedEvents] = useState<DiagnosisRegimeDetail[]>([]);
  const [decisionTreeCompleted, setDecisionTreeCompleted] = useState<boolean>(false);
  const [currentStepId, setCurrentStepId] = useState<number>();
  const [answers, setAnswers] = useState<DecisionTreeAnswers>({});

  const [animals, setAnimals] = useState<Animal[]>([]);
  const [eventDateTime, setEventDateTime] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [notes, setNotes] = useState<string>('');
  const [diagnosisId, setDiagnosisId] = useState<string>('');
  const [diagnosisRegimeId, setDiagnosisRegimeId] = useState<string>('');
  const [user, setUser] = useState<User>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  const settings = useContext(SettingsContext);

  const auth = useContext(AuthContext);

  useQuery<User | undefined>(
    [QueryKey.Users, auth.userId],
    () => userService.get(auth.userId as number),
    {
      onSuccess: (user) => {
        if (user) {
          // set default
          setUser(user);
        }
      },
    },
  );

  // when the form is validated scroll to the invalid elements
  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const mutation = useMutation(
    async () => {
      if (!user) {
        throw Error('Invalid user');
      }

      const events: PostPayloadEvent[] = [];
      completedEvents.forEach((drd) => {
        const productDosage = answers[drd.id] && answers[drd.id].productDosage;
        const productLocation = answers[drd.id] && answers[drd.id].productLocation;
        const productRoute = answers[drd.id] && answers[drd.id].productRoute;
        const productId = answers[drd.id] && answers[drd.id].productId;
        events.push({
          animalIds: animals.map((animal) => animal.id),
          animalEventTypeId: AnimalEventTypeEnum.Diagnosis,
          diagnosisId: Number(diagnosisId),
          diagnosisRegimeId: Number(diagnosisRegimeId),
          diagnosisRegimeDetailId: Number(drd.id),
          productId: productId,
          productDosage: Number(productDosage),
          productDosageFactor: 100,
          productRoute: productRoute ?? '',
          productLocation: productLocation,
          eventDateTime: parseISO(eventDateTime).toISOString(),
          notes: `Completed: ${drd.description} ${notes}`,
          userId: user.id,
        });
      });

      if (!currentStepId) {
        throw Error('Current step ID must be set');
      }

      const result = await activeTreatmentService.post({
        answers: answers,
        events: events,
        currentStep: currentStepId,
        completed: decisionTreeCompleted,
        status: decisionTreeCompleted ? TaskStatus.Completed : TaskStatus.Pending,
      });

      if (result && result.length > 0) {
        props.onSubmitSuccess && props.onSubmitSuccess(result[0]);
      }

      return result;
    },
    {
      onSuccess: async () => {
        // Invalidate and re-fetch
        await queryClient.invalidateQueries(QueryKey.AnimalEvents);
        // tasks can be created so we invalidate
        await queryClient.invalidateQueries(QueryKey.ActiveTreatments);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid || !user || animals.length === 0 || !diagnosisId || completedEvents.length === 0) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const fields = (
    <>
      <Form.Group className="mb-3 form-group" controlId="formAnimals">
        <Form.Label>
          {t('Animal')} <Required />
        </Form.Label>
        <AnimalAutocomplete
          id={'animals'}
          multiple={false}
          name={'animalIds'}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setAnimals([]);
            }

            setAnimals([selected]);
          }}
          required={true}
          selected={animals}
          validated={validated}
        />
        <p className={'my-3'}>
          {t('Animal Weight')}:{' '}
          <strong>
            {animals.length > 0 && animals[0].weight > 0
              ? `${convert(parseFloat(animals[0].weight))
                  .from('kg')
                  .to(settings?.weightUnit as Unit)
                  .toFixed(2)} ${settings?.weightUnit}`
              : '-'}
          </strong>
        </p>
      </Form.Group>
      <Form.Group className="mb-3 form-group" controlId="formDiagnosis">
        <Form.Label>
          {t('Diagnosis')} <Required />
        </Form.Label>
        <DiagnosisSelect
          name={'diagnosisId'}
          onChange={(e): void => setDiagnosisId(e.target.value)}
          onCloseAddModal={(): void => setShowAddDiagnosisModal(false)}
          required
          showAddModal={showAddDiagnosisModal}
          value={diagnosisId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a diagnosis from the dropdown or')} </span>
          <Button onClick={(): void => setShowAddDiagnosisModal(true)} size={'sm'} variant={'link'}>
            {t('add diagnosis')}
          </Button>
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 form-group" controlId="formDiagnosisRegime">
        <Form.Label>
          {t('Diagnosis Regime')} <Required />
        </Form.Label>
        <DiagnosisRegimeSelect
          diagnosisId={Number(diagnosisId)}
          disabled={!diagnosisId}
          firstItemDefault={true}
          name={'diagnosisRegimeId'}
          onChange={(value): void => setDiagnosisRegimeId(value)}
          onCloseAddModal={(): void => setShowAddDiagnosisRegimeModal(false)}
          required
          showAddModal={showAddDiagnosisRegimeModal}
          value={diagnosisRegimeId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a diagnosis regime from the dropdown or')} </span>
          <Button
            onClick={(): void => setShowAddDiagnosisRegimeModal(true)}
            size={'sm'}
            variant={'link'}
          >
            {t('add diagnosis regime')}
          </Button>
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 form-group" controlId="formPreview">
        {diagnosisRegimeId ? (
          <Button
            onClick={(): void => setShowDiagnosisRegimePreviewModal(true)}
            size={'sm'}
            variant={'link'}
          >
            {t('diagnosisForm|previewRegimeStepsLabel')}
          </Button>
        ) : (
          <p className={'text-muted'}>
            <em>{t('diagnosisForm|selectRegimeFirstText')}</em>
          </p>
        )}
      </Form.Group>
      <Form.Group className={'mb-3 form-group'}>
        {diagnosisRegimeId && (
          <DiagnosisDecisionTree
            animalWeight={animals.length > 0 && animals[0].weight ? animals[0].weight : undefined}
            diagnosisRegimeId={Number(diagnosisRegimeId)}
            isCalf={animals[0] ? animals[0].isCalf : false}
            onStepChange={(stepChange): void => {
              setCompletedEvents(stepChange.events);
              setDecisionTreeCompleted(stepChange.completed);
              setCurrentStepId(stepChange.currentStepId);

              // stepChange.answers contains ALL answers
              setAnswers(stepChange.answers);
            }}
          />
        )}
        {validated && completedEvents.length === 0 && (
          <div className=" invalid-feedback" style={{ display: 'block' }}>
            {t('diagnosisForm|eventMinimumError')}
          </div>
        )}
        {completedEvents.length > 0 && (
          // @TODO how to translate this?
          <p>
            <strong>{completedEvents.length}</strong> event{completedEvents.length !== 1 && 's'}{' '}
            will be created upon completing this form.
          </p>
        )}
      </Form.Group>
      <Form.Group className="mb-3 form-group" controlId="formUser">
        <Form.Label>
          {t('Technician')} <Required />
        </Form.Label>
        <UserAutocomplete
          id={'user'}
          multiple={false}
          name={'userId'}
          onCloseAddModal={(): void => setShowAddUserModal(false)}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setUser(undefined);
            }
            setUser(selected);
          }}
          required={true}
          selected={user}
          showAddModal={showAddUserModal}
          validated={validated}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a {{value}} from the dropdown or', { value: 'user' })} </span>
          <Button onClick={(): void => setShowAddUserModal(true)} size={'sm'} variant={'link'}>
            {t('add user')}
          </Button>
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3 form-group" controlId="formNotes">
        <Form.Label>{t('Notes')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'notes'}
          onChange={(event): void => setNotes(event.target.value)}
          rows={4}
          value={notes}
        />
        <Form.Text className={'text-muted'}>{t('common|eventDescriptionInstructions')}</Form.Text>
      </Form.Group>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('Additional Fields')}</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3 form-group" controlId="formEventDate">
              <Form.Label>
                {t('Event Date')} <Required />
              </Form.Label>
              <Form.Control
                aria-label={t('Event Date')}
                onChange={(event): void => setEventDateTime(event.target.value)}
                required={true}
                type={'date'}
                value={eventDateTime}
              />
              <Form.Control.Feedback type={'invalid'}>
                {t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      {fields}
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModal
          disabled={mutation.isLoading}
          label={t('Cancel')}
          onClick={props.onCancel}
          type={'button'}
          variant={ButtonVariant.Secondary}
        />
        <ButtonModal
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          icon={IconSave}
          label={t('Save')}
          type="submit"
        />
      </div>
      {showDiagnosisRegimePreviewModal && (
        <DiagnosisRegimePreviewModal
          diagnosisRegimeId={Number(diagnosisRegimeId)}
          onClose={(): void => setShowDiagnosisRegimePreviewModal(false)}
        />
      )}
    </Form>
  );
};

export default DiagnosisForm;
