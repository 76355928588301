// Copyright © 2024 CATTLEytics Inc.
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../common/components/Page';
import Schedules from './Schedules';

const SchedulesPage: VFC = () => {
  const { t } = useTranslation();
  return (
    <Page title={t('Schedules')}>
      <Schedules />
    </Page>
  );
};
export default SchedulesPage;
