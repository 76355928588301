// Copyright © 2023 CATTLEytics Inc.

import { AiOutlineSend } from 'react-icons/ai';
import {
  BiComment,
  BiCommentCheck,
  BiDoorOpen,
  BiDotsVertical,
  BiHide,
  BiLock,
} from 'react-icons/bi';
import { BsCaretRight, BsCodeSquare, BsExclamationCircleFill, BsQuestion } from 'react-icons/bs';
import { CgUndo } from 'react-icons/cg';
import {
  FaBell,
  FaCheck,
  FaClone,
  FaCog,
  FaCogs,
  FaCopy,
  FaExclamationTriangle,
  FaEye,
  FaFileImport,
  FaFileSignature,
  FaGripHorizontal,
  FaInfoCircle,
  FaLock,
  FaPencilAlt,
  FaPlay,
  FaRandom,
  FaSearch,
  FaSyringe,
  FaThumbsDown,
  FaThumbsUp,
  FaTimes,
  FaTrashAlt,
  FaUser,
  FaUsers,
  FaWrench,
} from 'react-icons/fa';
import { FiClock, FiLink, FiSkipForward, FiUpload, FiUserPlus } from 'react-icons/fi';
import { GiHamburgerMenu, GiMedicines, GiNotebook, GiTombstone } from 'react-icons/gi';
import { IoCartOutline } from 'react-icons/io5';
import {
  MdAdd,
  MdAddCircle,
  MdArrowForwardIos,
  MdChildFriendly,
  MdDownload,
  MdDryCleaning,
  MdFence,
  MdHelpCenter,
  MdListAlt,
  MdLogin,
  MdLogout,
  MdManageAccounts,
  MdNavigateBefore,
  MdNavigateNext,
  MdOutlinePlaylistAddCheck,
  MdOutlineTableRows,
  MdPassword,
  MdRestore,
  MdSave,
  MdTv,
  MdWarning,
} from 'react-icons/md';
import {
  RiCalendarEventFill,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
} from 'react-icons/ri';
import { SiHappycow } from 'react-icons/si';
import { SlTag } from 'react-icons/sl';
import { TbMilk, TbPrompt, TbSwitch2 } from 'react-icons/tb';
import { TiFlowMerge } from 'react-icons/ti';
import { VscSettings } from 'react-icons/vsc';

/**
 * Aliases for React Icons to standardize them across app.
 */

export const IconAddCircle = MdAddCircle;
export const IconAdmin = FaLock;
export const IconAnimal = SiHappycow;
export const IconAssign = FiUserPlus;
export const IconManageRoles = MdManageAccounts;
export const IconCancel = FaTimes;
export const IconCheck = FaCheck;
export const IconCode = BsCodeSquare;
export const IconComplete = RiCheckboxCircleLine;
export const IconCommandLine = TbPrompt;
export const IconConditional = TiFlowMerge;
export const IconCopyToClipboard = FaCopy;
export const IconComment = BiComment;
export const IconDots = BiDotsVertical;
export const IconDownload = MdDownload;
export const IconNotComplete = RiCheckboxBlankCircleLine;
export const IconCaretRight = MdArrowForwardIos;
export const IconCommentComplete = BiCommentCheck;
export const IconNavCaretRight = BsCaretRight;
export const IconCreate = MdAdd;
export const IconDelete = FaTrashAlt;
export const IconDrag = FaGripHorizontal;
export const IconDuplicate = FaClone;
export const IconEdit = FaPencilAlt;
export const IconError = FaExclamationTriangle;
export const IconEvent = RiCalendarEventFill;
export const IconGeneral = SiHappycow;
export const IconGenerate = FaRandom;
export const IconHelp = MdHelpCenter;
export const IconHide = BiHide;
export const IconImport = FaFileImport;
export const IconInfo = FaInfoCircle;
export const IconLink = FiLink;
export const IconList = MdListAlt;
export const IconListCheck = MdOutlinePlaylistAddCheck;
export const IconManage = FaWrench;
export const IconMedicine = GiMedicines;
export const IconMenu = GiHamburgerMenu;
export const IconNotifications = FaBell;
export const IconOperations = FaCogs;
export const IconPassword = MdPassword;
export const IconPen = MdFence;
export const IconPriorityHighest = BsExclamationCircleFill;
export const IconRestore = MdRestore;
export const IconRun = FaPlay;
export const IconSend = AiOutlineSend;
export const IconSave = MdSave;
export const IconSearch = FaSearch;
export const IconSettings = FaCog;
export const IconSetup = VscSettings;
export const IconSkip = FiSkipForward;
export const IconSwitcher = TbSwitch2;
export const IconSignIn = MdLogin;
export const IconSignOut = MdLogout;
export const IconSignUp = FaFileSignature;
export const IconStatusBred = SiHappycow;
export const IconStatusDied = GiTombstone;
export const IconStatusFresh = TbMilk;
export const IconStatusOpen = BiDoorOpen;
export const IconStatusReference = GiNotebook;
export const IconStatusSold = IoCartOutline;
export const IconSuperAdmin = BiLock;
export const IconTable = MdOutlineTableRows;
export const IconChevronRight = MdNavigateNext;
export const IconChevronLeft = MdNavigateBefore;
export const IconStatusDry = MdDryCleaning;
export const IconStatusPregnant = MdChildFriendly;
export const IconStatusUnknown = BsQuestion;
export const IconTag = SlTag;
export const IconThumbsUp = FaThumbsUp;
export const IconThumbsDown = FaThumbsDown;
export const IconTreatment = FaSyringe;
export const IconUndo = CgUndo;
export const IconUpload = FiUpload;
export const IconUser = FaUser;
export const IconUsers = FaUsers;
export const IconView = FaEye;
export const IconWait = FiClock;
export const IconWarning = MdWarning;
export const IconWhiteboard = MdTv;
