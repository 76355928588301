// Copyright © 2024 CATTLEytics Inc.
import { VFC } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

const weekdays = [
  { day: 0, label: 'S' },
  { day: 1, label: 'M' },
  { day: 2, label: 'T' },
  { day: 3, label: 'W' },
  { day: 4, label: 'T' },
  { day: 5, label: 'F' },
  { day: 6, label: 'S' },
];

type Props = {
  days: number[];
  disabled?: boolean;
  id?: string;
  onChange: (value: number[]) => void;
};

export const WeekdaySelector: VFC<Props> = ({ id = `Weekday`, days, disabled, onChange }) => {
  return (
    <ToggleButtonGroup className="mb-3" onChange={onChange} type="checkbox" value={days}>
      {weekdays.map(({ day, label }) => (
        <ToggleButton
          className={`px-3`}
          disabled={disabled}
          id={`${id}-${day}`}
          key={day}
          value={day}
          variant="outline-primary"
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
