// Copyright © 2023 CATTLEytics Inc.

import { format, parseISO } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AnimalAutocomplete from '../../../animals/components/AnimalAutocomplete';
import AlertErrorForModal from '../../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../../common/components/Button';
import ButtonModal from '../../../common/components/ButtonModal';
import Required from '../../../common/components/Required';
import AnimalEvent from '../../../common/entities/animalEvent';
import Pen from '../../../common/entities/pen';
import User from '../../../common/entities/user';
import { AnimalEventType as AnimalEventTypeEnum } from '../../../common/enums';
import AuthContext from '../../../common/store/auth-context';
import { api, IconSave } from '../../../common/utilities';
import { Animal } from '../../../shared';
import { ApiResourceV1, QueryKey } from '../../../shared/enums';
import UserAutocomplete from '../../../users/components/UserAutocomplete';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when form is cancelled.
   */
  onCancel?: () => void;

  /**
   * Callback when form has been submitted successfully..
   */
  onSubmitSuccess?: () => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;
}

interface Payload {
  [key: string]: any | undefined;
  animalEventTypeId: number;
  animalIds: number[];
  eventDateTime: string;
  notes: string;
  userId: number;
}

const DriedOffForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);

  const [eventDateTime, setEventDateTime] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [notes, setNotes] = useState<string>('');
  const [animals, setAnimals] = useState<Animal[]>([]);
  const [user, setUser] = useState<User>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const mutation = useMutation<AnimalEvent[] | undefined, unknown, Payload>(
    (payload) => {
      return api('POST', `${ApiResourceV1.AnimalEvents}`, { body: payload });
    },
    {
      onSuccess: async () => {
        // Invalidate and re-fetch
        await queryClient.invalidateQueries(QueryKey.AnimalEvents);
      },
    },
  );
  const queryClient = useQueryClient();

  const auth = useContext(AuthContext);

  useQuery<User | undefined>(
    [QueryKey.Users, auth.userId],
    () => api('GET', `${ApiResourceV1.Users}/${auth.userId}`),
    {
      onSuccess: (user) => {
        if (user) {
          // set default user
          setUser(user);
        }
      },
    },
  );

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid || !user) {
      return;
    }

    const payload: Payload = {
      animalIds: animals.map((animal) => animal.id),
      animalEventTypeId: AnimalEventTypeEnum.DriedOff,
      eventDateTime: parseISO(eventDateTime).toISOString(),
      notes: notes,
      userId: user.id,
    };

    setErrorMessage('');
    try {
      await mutation.mutateAsync(payload);
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess();
      }
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="formEventDate">
        <Form.Label>
          {t('Event Date')} <Required />
        </Form.Label>
        <Form.Control
          aria-label="Event Date"
          onChange={(event): void => setEventDateTime(event.target.value)}
          required={true}
          type={'date'}
          value={eventDateTime}
        />
        <Form.Control.Feedback type={'invalid'}>This field is required.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formAnimals">
        <Form.Label>
          {t('Animal')} <Required />
        </Form.Label>
        <AnimalAutocomplete
          id={'animals'}
          multiple={false}
          name={'animalIds'}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setAnimals([]);
            }

            setAnimals([selected]);
          }}
          required={true}
          selected={animals}
          validated={validated}
        />
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="TechnicianControlInput">
        <Form.Label>User</Form.Label>
        <UserAutocomplete
          id={'user'}
          multiple={false}
          name={'userId'}
          onCloseAddModal={(): void => setShowAddUserModal(false)}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or User
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setUser(undefined);
            }

            setUser(selected);
          }}
          required={false}
          selected={user}
          showAddModal={showAddUserModal}
          validated={validated}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a {{value}} from the dropdown or', { value: 'user' })} </span>
          <Button onClick={(): void => setShowAddUserModal(true)} size={'sm'} variant={'link'}>
            {t('add user')}
          </Button>
        </Form.Text>
      </Form.Group>

      <FormGroup className="form-group mb-3" controlId="formNotes">
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'notes'}
          onChange={(event): void => setNotes(event.target.value)}
          rows={4}
          value={notes}
        />
        <Form.Text className={'text-muted'}>{t('common|eventDescriptionInstructions')}</Form.Text>
      </FormGroup>
    </>
  );
  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      {fields}
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModal
          disabled={mutation.isLoading}
          label={t('Cancel')}
          onClick={props.onCancel}
          type={'button'}
          variant={ButtonVariant.Secondary}
        />
        <ButtonModal
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          icon={IconSave}
          label={t('Save')}
          type="submit"
        />
      </div>
    </Form>
  );
};

export default DriedOffForm;
