// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import UserService from '../../common/services/userService';
import { IconUser } from '../../common/utilities/icons';
import { User } from '../../shared';
import { QueryKey } from '../../shared/enums';
import UserModal from './UserModal';

/**
 * Component properties
 */
interface Props {
  /**
   * Loading indicator.
   */
  busy?: boolean;

  disabled?: boolean;

  /**
   * HTML ID attribute
   */
  id: string;

  /**
   * Whether to allow multiple pen selection.
   *
   * Note: this also changes the parameters sent in the onSelect callback from
   * single item to array of items.
   */
  multiple?: boolean;

  /**
   * HTML name attribute
   */
  name?: string;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Callback after pen has been selected
   * @param user
   */
  onSelect: (user?: User | User[]) => void;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option or options.
   */
  selected?: Option | Option[];

  /**
   * Whether to display the add modal
   */
  showAddModal?: boolean;

  /**
   * Whether the form this component is in has been validated.
   * This will show invalid messages if applicable.
   * Note this does not indicate a form is valid.
   */
  validated?: boolean;
}

/**
 * Provides ability to select a user by name or ID.
 */
const UserAutocomplete = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const userService = useInjection<UserService>(TYPES.userService);

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  const [searchQuery, setSearchQuery] = useState<string>(
    props.selected ? String(props.selected) : '',
  );

  const limit = 10;

  const { data: users, isLoading: busy } = useQuery<User[]>(
    [QueryKey.Users, searchQuery, limit],
    () =>
      userService.list({
        search: searchQuery,
        limit: String(limit),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  // if selected property is not set then empty array,
  // else if selected property is an array use it,
  // else make selected property an array
  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  // if the validated property is set and true (form validation was run),
  // and the field is required and the selection is empty
  const isInvalid = !!props.validated && required && selected.length === 0;

  // if the validated property is set and true (form validation was run),
  // and the field is not isInvalid
  const isValid = !!props.validated && !isInvalid;

  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      readOnly={true}
      required={required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={selected.length === 0 ? '' : '1'}
    />
  );

  return (
    <div className={`user-autocomplete ${isInvalid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <AsyncTypeahead
        clearButton={true}
        disabled={props.disabled}
        id={props.id} // for styling only
        inputProps={{ required: isInvalid }}
        isInvalid={isInvalid}
        isLoading={busy || !!props.busy}
        isValid={isValid}
        labelKey={(option): string => {
          const user = option as User;
          return `${user.firstName} ${user.lastName}`;
        }}
        minLength={0}
        multiple={props.multiple}
        onChange={(selected): void => {
          if (props.multiple) {
            props.onSelect(selected && selected.length > 0 ? (selected as User[]) : undefined);
          } else {
            props.onSelect(selected && selected.length > 0 ? (selected[0] as User) : undefined);
          }
        }}
        onSearch={(searchQuery: string): void => {
          setSearchQuery(searchQuery);
        }}
        options={users ?? []}
        placeholder={'Enter an user identifier'}
        renderMenuItemChildren={(option, { text }): JSX.Element => {
          const user = option as User;
          const label = `${user.firstName} ${user.lastName}`;
          return (
            <div
              className={'d-flex justify-space-between align-items-center'}
              style={{ borderBottom: '1px #ccc solid', paddingBottom: '8px' }}
            >
              {user.imageUrlSigned ? (
                <img
                  alt={label}
                  className={'rounded'}
                  src={user.imageUrlSigned}
                  style={{ width: '60px', height: '60px', marginRight: '2rem' }}
                />
              ) : (
                <div
                  className={'rounded d-flex justify-content-center align-items-center'}
                  style={{
                    border: '1px #ccc solid',
                    objectFit: 'cover',
                    height: '60px',
                    width: '60px',
                    lineHeight: '60p',
                    backgroundColor: '#ccc',
                    paddingBottom: '0rem',
                    marginRight: '2rem',
                  }}
                >
                  <IconUser style={{ fontSize: '2.5rem', color: '#999' }} />
                </div>
              )}
              <div className={'d-block text-wrap'}>
                <Highlighter search={text}>{label}</Highlighter>
                <p className={'text-muted'}>
                  <small>ID: {user.id ?? '-'}</small>
                </p>
              </div>
            </div>
          );
        }}
        selected={selected}
      />
      <Form.Control.Feedback type={'invalid'}>
        {t('common|fieldRequiredFeedback')}
      </Form.Control.Feedback>
      {showAddModal ? (
        <UserModal onClose={props.onCloseAddModal ?? ((): void => undefined)} />
      ) : null}
    </div>
  );
};

export default UserAutocomplete;
