// Copyright © 2023 CATTLEytics Inc.

import convert, { Unit } from 'convert-units';
import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Form, InputGroup, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import ButtonSave from '../../common/components/ButtonSave';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Toast from '../../common/components/Toast';
import Setting from '../../common/entities/setting';
import SettingService from '../../common/services/settingService';
import SettingsContext from '../../common/store/setings-context';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

/**
 * Animal settings tab.
 */
const AnimalsTab = (): JSX.Element => {
  const { t } = useTranslation();

  const settingService = useInjection<SettingService>(TYPES.settingService);
  const logger = useInjection<Logger>(TYPES.logger);
  const [typicalWeight, setTypicalWeight] = useState<string>('');
  const [typicalCalfWeight, setTypicalCalfWeight] = useState<string>('');

  const settingsLocalization = useContext(SettingsContext);

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const queryClient = useQueryClient();

  const query = useQuery<Setting | undefined>(
    [QueryKey.Settings, 'animals'],
    () => settingService.get('animals'),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  useEffect(() => {
    if (query.data && query.data.typicalWeight) {
      const weight =
        settingsLocalization.weightUnit === 'kg'
          ? query.data.typicalWeight
          : convert(Number(query.data.typicalWeight))
              .from('kg')
              .to(settingsLocalization.weightUnit as Unit)
              .toFixed(2);

      setTypicalWeight(String(weight));
    }
    if (query.data && query.data.typicalCalfWeight) {
      const weight =
        settingsLocalization.weightUnit === 'kg'
          ? query.data.typicalCalfWeight
          : convert(Number(query.data.typicalCalfWeight))
              .from('kg')
              .to(settingsLocalization.weightUnit as Unit)
              .toFixed(2);

      setTypicalCalfWeight(String(weight));
    }
  }, [query.data, settingsLocalization]);

  const mutation = useMutation(
    () => {
      const weight =
        settingsLocalization.weightUnit === 'kg'
          ? typicalWeight
          : convert(Number(typicalWeight))
              .from(settingsLocalization.weightUnit as Unit)
              .to('kg')
              .toFixed(2);

      const calfWeight =
        settingsLocalization.weightUnit === 'kg'
          ? typicalCalfWeight
          : convert(Number(typicalCalfWeight))
              .from(settingsLocalization.weightUnit as Unit)
              .to('kg')
              .toFixed(2);

      return settingService.patch('animals', {
        typicalWeight: weight,
        typicalCalfWeight: calfWeight,
      });
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Settings);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      logger.error('Settings could not be saved.', err);
      setErrorMessage(t('animalsTab|settingsNotSavedError'));
    }
  };

  const placeholder = <PlaceholderForm fields={3} />;

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="typicalWeight">
        <Form.Label>{t('Typical Weight')}</Form.Label>
        <InputGroup>
          <Form.Control
            aria-describedby={'typical-weight-unit'}
            aria-label={t('Typical Weight')}
            name={'typicalWeight'}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => setTypicalWeight(e.target.value)}
            type={'number'}
            value={typicalWeight}
          />
          <InputGroup.Text id="typical-weight-unit">
            {settingsLocalization?.weightUnit}
          </InputGroup.Text>
        </InputGroup>
        <Form.Text className={'text-muted'}>{t('animalsTab|typicalWeightText')}</Form.Text>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="typicalCalfWeight">
        <Form.Label>{t('Typical Calf Weight')}</Form.Label>
        <InputGroup>
          <Form.Control
            aria-describedby={'typical-weight-unit'}
            aria-label={t('Typical Calf Weight')}
            name={'typicalCalfWeight'}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              setTypicalCalfWeight(e.target.value)
            }
            type={'number'}
            value={typicalCalfWeight}
          />
          <InputGroup.Text id="typical-weight-unit">
            {settingsLocalization?.weightUnit}
          </InputGroup.Text>
        </InputGroup>
        <Form.Text className={'text-muted'}>
          {t(
            'The typical weight of a calf used to help calculate treatment dosages when a calf weight is unknown.',
          )}
        </Form.Text>
      </Form.Group>
    </>
  );

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isFetching ? placeholder : fields}

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('Settings saved')}
      </Toast>
    </Form>
  );
};

export default AnimalsTab;
