// Copyright © 2024 CATTLEytics Inc.
import { useInjection } from 'inversify-react';
import { groupBy } from 'lodash';
import { ReactNode, useCallback, useContext, useMemo, useState, VFC } from 'react';
import { ListGroup, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { TYPES } from '../../types';
import DeleteConfirmModal from '../common/components/DeleteConfirmModal';
import FormFieldWrapper from '../common/components/FormFieldWrapper';
import { useDeleteConfirmModal } from '../common/hooks';
import UserService from '../common/services/userService';
import AuthContext from '../common/store/auth-context';
import { api, IconCancel, IconDuplicate, isSiteAdminOrAbove } from '../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, User, UserSchedule } from '../shared';
import UserAutocomplete from '../users/components/UserAutocomplete';
import { getShiftDateString } from './shiftHelpers';

type Props = {
  onNoSchedules?: () => void;
  schedules?: UserSchedule[];
};

const ManageSchedulesForm: VFC<Props> = ({ onNoSchedules, schedules }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [userSchedules, setUserSchedules] = useState<UserSchedule[]>(
    schedules?.length ? schedules : [],
  );
  const [showDeleteUserSchedule, setShowDeleteUserSchedule] = useState<UserSchedule>();
  const shift = useMemo(() => userSchedules?.[0]?.shift, [userSchedules]);
  const {
    deleteConfirmModalOpen,
    deleteConfirmModalErrorMessage,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
  } = useDeleteConfirmModal();

  const openModal = useCallback(
    (userSchedule: UserSchedule) => {
      setShowDeleteUserSchedule(userSchedule);
      openDeleteConfirmModal();
    },
    [openDeleteConfirmModal, setShowDeleteUserSchedule],
  );

  const onSuccess = useCallback(() => {
    closeDeleteConfirmModal();
    setShowDeleteUserSchedule(undefined);
    setUserSchedules((prev) => prev.filter(({ id }) => id !== showDeleteUserSchedule?.id));
    if (userSchedules.length <= 1) {
      onNoSchedules?.();
    }
  }, [
    closeDeleteConfirmModal,
    setShowDeleteUserSchedule,
    setUserSchedules,
    onNoSchedules,
    userSchedules,
    showDeleteUserSchedule,
  ]);
  const userService = useInjection<UserService>(TYPES.userService);
  const auth = useContext(AuthContext);
  const isEditable = useMemo(() => isSiteAdminOrAbove(auth), [auth]);

  const { mutateAsync: saveAsync } = useMutation<
    UserSchedule[] | undefined,
    unknown,
    { users: User[] }
  >(
    ({ users }) => {
      const method = HttpMethod.Post;
      const path: string = ApiResourceV1.UserSchedulesBulk;

      return api(method, path, {
        body: {
          userIds: users.map((u) => u.id),
          shiftId: shift?.id,
          dates: [userSchedules[0].date],
        },
      });
    },
    {
      onSuccess: async (data) => {
        if (data?.length) {
          const schedule = data[0];
          const user = await userService.get(schedule.userId);
          setUserSchedules((prev) => [...prev, { ...schedule, user }]);
          queryClient.invalidateQueries(QueryKey.UserSchedules);
        }
      },
    },
  );

  const { isLoading: deleteIsLoading, mutateAsync: deleteAsync } = useMutation<
    UserSchedule | undefined
  >(
    () => {
      if (showDeleteUserSchedule?.id) {
        return api(
          HttpMethod.Delete,
          `${ApiResourceV1.UserSchedules}/${showDeleteUserSchedule.id}`,
        );
      }
      return Promise.reject();
    },
    {
      onSuccess,
    },
  );

  const onDelete = useCallback(() => deleteAsync(), [deleteAsync]);

  const dateStr = useMemo(
    () => shift && getShiftDateString(shift, userSchedules),
    [shift, userSchedules],
  );

  const listItems = useMemo(() => {
    const userMap = groupBy(userSchedules, ({ userId }) => userId);

    return Object.values(userMap).reduce<ReactNode[]>((prev, schedules) => {
      const isDup = schedules.length > 1;

      const items = schedules.reduce<ReactNode[]>((schedulePrev, schedule, i) => {
        if (schedule.user) {
          const { firstName, lastName, id } = schedule.user;

          schedulePrev.push(
            <ListGroup.Item
              as="li"
              className={`list-group-item d-flex justify-content-between align-items-center ${
                isDup ? 'bg-warning bg-opacity-50' : ''
              }`}
              key={`${id}-${i}`}
              title={isDup ? t('Duplicate') : undefined}
            >
              <Stack direction="horizontal" gap={2}>
                {isDup && <IconDuplicate />}
                <div>{`${firstName} ${lastName}`}</div>
              </Stack>
              {isEditable && (
                <IconCancel
                  onClick={(): void => openModal(schedule)}
                  style={{ cursor: 'pointer', width: '1.3rem', height: '1.3rem' }}
                  title={t('Delete')}
                />
              )}
            </ListGroup.Item>,
          );
        }

        return schedulePrev;
      }, []);

      return [...prev, ...items];
    }, []);
  }, [userSchedules, isEditable, openModal, t]);

  return (
    <>
      <Stack gap={2}>
        <div className="mb-2">
          {t('The user(s) below are scheduled to work on {{date}}', {
            date: dateStr,
          })}
        </div>
        {isEditable && (
          <FormFieldWrapper
            controlId="formUser"
            invalidFeedback={t('common|fieldRequiredFeedback')}
            label={t('manageSchedulesForm|addUserLabel')}
          >
            <UserAutocomplete
              id="userSelect"
              // disabled={isLoading}
              multiple={true}
              name={'userId'}
              onSelect={(selected): void => {
                if (selected && !Array.isArray(selected)) {
                  saveAsync({ users: [selected] });
                }
                if (selected && Array.isArray(selected)) {
                  saveAsync({ users: selected });
                }
              }}
            />
          </FormFieldWrapper>
        )}
        <ListGroup as="ul">{listItems}</ListGroup>
      </Stack>
      <DeleteConfirmModal
        busy={deleteIsLoading}
        cancelOnClick={(): void => closeDeleteConfirmModal()}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t('Yes, remove this schedule')}
        okOnClick={onDelete}
        title={t('Remove this schedule')}
        value={t('schedule')}
        visible={deleteConfirmModalOpen}
      />
    </>
  );
};

export default ManageSchedulesForm;
