// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run bin/copy-shared-to-packages.sh
 */

export enum Role {
  /**
   * Full read/write permissions to all data in a specific site.
   */
  SiteAdministrator = 'site-admin',

  /**
   * Read/write permissions to most data except administrator actions for a specific site.
   */
  User = 'user',
}
