// Copyright © 2023 CATTLEytics Inc.

import './NotesWhiteboardList.scss';

import { useInjection } from 'inversify-react';
import React, { Fragment, useState } from 'react';
import {
  Accordion,
  Badge,
  Button as ButtonBootstrap,
  Card,
  Dropdown,
  Placeholder,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import ConfirmModal from '../../common/components/ConfirmModal';
import DateTime from '../../common/components/DateTime';
import Note from '../../common/entities/note';
import { Sort } from '../../common/enums';
import NoteService from '../../common/services/noteService';
import { IconDelete, IconDots, IconEdit, IconView } from '../../common/utilities';
import { nameTag } from '../../shared';
import { QueryKey } from '../../shared/enums';
import NoteModal from './NoteModal';

interface Props {
  /**
   * Filter list by animal ID.
   */
  animalId?: number;

  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Number of notes to show.
   */
  limit?: number;

  /**
   * Callback when list data is successfully retrieved
   * @param data
   */
  onQuerySuccess?: (data: Note[]) => void;

  /**
   * Callback when the "View" button is clicked
   */
  onView: (noteId: number) => void;

  /**
   * Filter list by pen ID.
   */
  penId?: number;

  /**
   * Direction to sort the list
   */
  sortDirection?: Sort;

  /**
   * Column to sort the list by
   */
  sortField?: string;

  /**
   * Tags to filter the list by
   */
  tags?: string[];
}

/**
 * List notes in a display suitable for whiteboard component.
 */
const NotesWhiteboardList = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [noteModalNoteId, setNoteModalNoteId] = useState<number>();
  const [noteDeleteModalVisible, setNoteDeleteModalVisible] = useState<boolean>(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState<number>();
  const noteService = useInjection<NoteService>(TYPES.noteService);

  const queryParams: Record<string, string> = {
    sortField: props.sortField ? String(props.sortField) : 'createdDate',
    sortDirection: props.sortDirection ? String(props.sortDirection) : Sort.Descending,
    limit: props.limit ? String(props.limit) : '10',
  };

  if (props.animalId) {
    queryParams.animalId = String(props.animalId);
  }

  if (props.penId) {
    queryParams.penId = String(props.penId);
  }

  if (props.tags) {
    queryParams.tags = props.tags.join(',');
  }

  const query = useQuery<Note[]>(
    [QueryKey.Notes, queryParams],
    () => noteService.list(queryParams),
    {
      onSuccess: props.onQuerySuccess,
      enabled: !noteModalVisible,
    },
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => {
      if (noteIdToDelete) {
        return noteService.delete(noteIdToDelete);
      } else {
        return new Promise<null>((resolve) => resolve(null));
      }
    },
    {
      onSuccess: () => {
        // Invalidate and re-fetch
        queryClient.invalidateQueries(QueryKey.Notes);
      },
    },
  );

  const placeholder = (
    <Accordion.Item eventKey={'loading'}>
      <Accordion.Header>
        <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
          <div className={'w-100 d-flex'}>
            <Placeholder className={'me-3'} md={2} />
            <Placeholder md={4} />
          </div>
          <Placeholder className={'me-1'} md={1} />
        </Placeholder>
      </Accordion.Header>
    </Accordion.Item>
  );

  if (query.isLoading) {
    return (
      <Fragment>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </Fragment>
    );
  }

  if (query.isError || !query.data) {
    return <AlertError message={'Error'} />;
  }

  const showModal = (noteId: number): void => {
    setNoteModalVisible(true);
    setNoteModalNoteId(noteId);
  };

  const showDeleteModal = (note: Note): void => {
    setNoteDeleteModalVisible(true);
    setNoteIdToDelete(note.id);
  };

  const notes = query.data;

  return (
    <div className={'notes-whiteboard-list'}>
      {notes.length === 0 && query.isFetched && (
        <p className={'text-center'}>
          <em>No notes found</em>
        </p>
      )}
      {notes.map((note) => {
        const related = [];
        if (note.animalId || note.penId) {
          if (note.animal) {
            related.push({
              link: `/animals/${note.animalId}`,
              label: `View ${nameTag(note.animal.primaryTag, note.animal.name)}`,
              icon: IconView,
            });
          }
          if (note.pen) {
            related.push({
              link: `/pens/${note.penId}`,
              label: `View Pen ${note.pen.name}`,
              icon: IconView,
            });
          }
        }
        return (
          <Card>
            <Card.Body key={note.id}>
              <div className={'text-truncate text-truncate-clamp-4'}>
                {note.body.split('\n').map((elem) => (
                  <>
                    {elem}
                    <br />
                  </>
                ))}
              </div>
              <hr />
              <div className={'d-flex justify-content-between flex-wrap'}>
                {note.animalId && (
                  <div>
                    <Badge bg={'primary'} className={'me-3'} pill>
                      Animal
                    </Badge>
                  </div>
                )}
                {note.penId && (
                  <div>
                    <Badge bg={'secondary'} className={'me-3'} pill>
                      Pen
                    </Badge>
                  </div>
                )}

                <div className={'mx-1'}>
                  <small className={'text-muted'} style={{ fontSize: '11px' }}>
                    <DateTime date={note.modifiedDate} />
                  </small>
                </div>

                <Dropdown>
                  <Dropdown.Toggle
                    as={ButtonBootstrap}
                    className={'m-0 p-0 px-1'}
                    variant={'outline-primary'}
                  >
                    <IconDots />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={(): void => props.onView(note.id)}>
                      <IconView /> {t('notesWhiteboardList|viewNoteLabel')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(): void => showModal(note.id)}>
                      <IconEdit /> {t('Edit')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(): void => showDeleteModal(note)}>
                      <IconDelete /> {t('Delete')}
                    </Dropdown.Item>
                    {related.map((item, index) => (
                      <Dropdown.Item as={NavLink} key={index} to={item.link}>
                        <item.icon /> {item.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card.Body>
          </Card>
        );
      })}
      {noteModalVisible && (
        <NoteModal noteId={noteModalNoteId} onClose={(): void => setNoteModalVisible(false)} />
      )}
      {noteDeleteModalVisible && (
        <ConfirmModal
          busy={mutation.isLoading}
          cancelOnClick={(): void => setNoteDeleteModalVisible(false)}
          okOnClick={async (): Promise<void> => {
            await mutation.mutateAsync();
            setNoteDeleteModalVisible(false);
          }}
          title={'Delete note'}
          visible={true}
        >
          {t('Are you sure you want to delete this {{value}}', { value: t('note') })}
        </ConfirmModal>
      )}
    </div>
  );
};

export default NotesWhiteboardList;
