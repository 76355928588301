// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run bin/copy-shared-to-packages.sh
 */

export enum DiagnosisRegimeDetailType {
  Conditional = 'conditional',
  Treatment = 'treatment',
  WaitAndSee = 'wait-and-see',
}
