// Copyright © 2023 CATTLEytics Inc.

import { format as dateFnsFormat, formatDistanceToNow, isToday, parseISO } from 'date-fns';
import React, { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SettingsContext from '../store/setings-context';

/**
 * Defines the input properties for the Date component.
 */
interface Props {
  /**
   * Date to display
   */
  date?: Date | string;

  /**
   * Date format.
   */
  format?: string;

  /**
   * Whether to include time. Note: if hideTime is true "Today" will be
   * returned as the distance formatted date/time.
   */
  hideTime?: boolean;

  /**
   * If true displays the date distance from now when hovering over the date.
   * If false displays the formatted date when hovering over the distance from now date.
   */
  showDistanceOnHover?: boolean;
}

/**
 * A component for displaying date and time.
 */
const DateTime = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);

  // if the show distance on hover property is set use it
  // otherwise check the preferredDateTimeStyle setting
  // if the preferredDateTimeStyle setting is set to absolute then
  // we will show the absolute date and the relative (distance) on hover
  const showDistanceOnHover =
    props.showDistanceOnHover !== undefined
      ? props.showDistanceOnHover
      : settings.preferredDateTimeStyle === 'absolute';

  const [dateFormatted, dateDistanceFormatted] = ((): string[] => {
    if (!props.date) {
      return [t('Invalid date'), t('Invalid date')];
    }
    // if date is a string, parse to Date object
    const date = typeof props.date === 'string' ? parseISO(props.date) : props.date;

    // set the date distance but if we are not supposed to show time and the date is today,
    // set the distance to simply Today as we don't want "x minutes ago"
    const distance =
      props.hideTime && isToday(date) ? 'Today' : formatDistanceToNow(date, { addSuffix: true });

    try {
      // a date/time format was specified, use it
      if (props.format) {
        return [dateFnsFormat(date, props.format), distance];
      }

      // use the date time formats from settings but only use date format if we are hiding time
      const format = props.hideTime
        ? settings.dateFormat
        : `${settings.dateFormat} ${settings.timeFormat}`;

      return [dateFnsFormat(date, format), distance];
    } catch (e) {
      return [t('Invalid date'), t('Invalid date')];
    }
  })();

  const hover = showDistanceOnHover ? dateDistanceFormatted : dateFormatted;
  const value = showDistanceOnHover ? dateFormatted : dateDistanceFormatted;

  return (
    <>
      {!props.date && <>-</>}
      {props.date && (
        <OverlayTrigger
          defaultShow={undefined}
          delay={undefined}
          flip={undefined}
          onHide={undefined}
          onToggle={undefined}
          overlay={<Tooltip>{hover}</Tooltip>}
          placement="bottom"
          popperConfig={undefined}
          show={undefined}
          target={undefined}
          trigger={undefined}
        >
          <span className={'text-nowrap'}>{value}</span>
        </OverlayTrigger>
      )}
    </>
  );
};

export default DateTime;
