// Copyright © 2023 CATTLEytics Inc.

import 'chartjs-adapter-date-fns';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import { api } from '../../common/utilities/api';
import { ReportBredOutcome } from '../../shared';
import { ApiResourceV1 } from '../../shared/enums/api';
import { HttpMethod } from '../../shared/enums/http';
import { QueryKey } from '../../shared/enums/queryKeys';

const BredOutcomeTable = (): JSX.Element => {
  const { t } = useTranslation();

  const query = useQuery<Required<ReportBredOutcome>[]>([QueryKey.ReportBredOutcome], () =>
    api<Required<ReportBredOutcome>[]>(HttpMethod.Get, ApiResourceV1.ReportBredOutcome),
  );

  const headers: DataTableHeader[] = [
    {
      name: 'dateValue',
      label: t('reproductionManagement|bredOutcomeTable|dateValueColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|dateValueToolTip'),
    },
    {
      name: 'bredEligible',
      label: t('reproductionManagement|bredOutcomeTable|bredEligibleColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|bredEligibleToolTip'),
    },
    {
      name: 'bred',
      label: t('reproductionManagement|bredOutcomeTable|bredColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|bredToolTip'),
    },
    {
      name: 'bredPct',
      label: t('reproductionManagement|bredOutcomeTable|bredPctColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|bredPctToolTip'),
    },
    {
      name: 'pregnantEligible',
      label: t('reproductionManagement|bredOutcomeTable|pregnantEligibleColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|pregnantEligibleToolTip'),
    },
    {
      name: 'pregnant',
      label: t('reproductionManagement|bredOutcomeTable|pregnantColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|pregnantToolTip'),
    },
    {
      name: 'pregnantPct',
      label: t('reproductionManagement|bredOutcomeTable|pregnantPctColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|pregnantPctToolTip'),
    },
    {
      name: 'aborts',
      label: t('reproductionManagement|bredOutcomeTable|abortsColumn'),
      infoTooltip: t('reproductionManagement|bredOutcomeTable|abortsToolTip'),
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        dateValue: format(new Date(row.dateValue), 'yyyy-MM-dd'),
        bredEligible: row.bredEligible.toString(),
        bred: row.bred.toString(),
        bredPct: row.bredPct.toString() + '%',
        pregnantEligible: row.pregnantEligible.toString(),
        pregnant: row.pregnant.toString(),
        pregnantPct: row.pregnantPct.toString() + '%',
        aborts: row.aborts.toString(),
      }));

  return (
    <DataTable
      className="text-nowrap"
      data={data}
      headers={headers}
      hidePaginationControls={true}
      isLoading={query.isLoading}
      messageNoData={t('reproductionManagement|bredOutcomeTable|noDataAvailable')}
    />
  );
};

export default BredOutcomeTable;
