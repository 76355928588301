// Copyright © 2023 CATTLEytics Inc.
import './AnimalCardEvents.scss';

import { VFC } from 'react';
import { Stack } from 'react-bootstrap';

import EventsTable from '../../../events/components/EventsTable';
import { useGetEventDates } from './hooks';
import { AnimalCardEventsPlaceholder, NoEventsMessage } from './messages';
import { AnimalCardEventsProvider, useAnimalCardEvents } from './provider';
import AnimalEventsTimeline from './timeline';
import Toolbar from './toolbar';

const AnimalCardEvents: VFC = () => {
  const { animalId, filters } = useAnimalCardEvents();
  const { dates, isLoading } = useGetEventDates({ animalId, filters });

  if (isLoading) {
    return <AnimalCardEventsPlaceholder />;
  }

  if (!dates.length) {
    return <NoEventsMessage />;
  }

  return (
    <Stack className="align-items-stretch" direction="vertical" gap={3}>
      <AnimalEventsTimeline eventDates={dates} />
      <EventsTable
        eventTypeIds={filters.animalEventTypeIds}
        filters={{
          animalId: animalId,
          dateStart: filters.dateStart.toISOString(),
          dateEnd: filters.dateEnd.toISOString(),
        }}
      />
    </Stack>
  );
};

const AnimalCardEventsLayout: VFC = () => {
  return (
    <Stack className="animal-card-events" gap={3}>
      <Toolbar />
      <AnimalCardEvents />
    </Stack>
  );
};

type Props = {
  animalId: string;
};

const AnimalCardEventsWrapper: VFC<Props> = ({ animalId }) => {
  return (
    <AnimalCardEventsProvider animalId={animalId}>
      <AnimalCardEventsLayout />
    </AnimalCardEventsProvider>
  );
};

export default AnimalCardEventsWrapper;
