// Copyright © 2023 CATTLEytics Inc.
import { useInjection } from 'inversify-react';
import { FC } from 'react';
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from 'react-mentions';
import { useQuery } from 'react-query';

import { TYPES } from '../../../../types';
import { nameTag, QueryKey } from '../../../shared';
import AnimalService from '../../services/animalService';

type Props = Omit<MentionsInputProps, 'children'>;

export const AnimalMentionsTextArea: FC<Props> = (props) => {
  const animalService = useInjection<AnimalService>(TYPES.animalService);
  const { data = [] } = useQuery([QueryKey.Animals], async () => {
    const animals = await animalService.getAnimals();
    return animals.map<SuggestionDataItem>(({ id, name, primaryTag }) => ({
      id,
      display: nameTag(primaryTag, name),
    }));
  });

  return (
    <MentionsInput {...props} className="mentions">
      <Mention data={data} displayTransform={(id, name): string => `@${name}`} trigger="@" />
    </MentionsInput>
  );
};
