// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { User } from '../../shared';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/users';

/**
 * Service for managing animal users
 */
@injectable()
export default class UserService implements DataService<User> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of UserService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of users
   * @return User objects
   */
  async list(params?: Record<string, string>): Promise<User[]> {
    const result = await this.apiService.get<User[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as User[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<User | undefined> {
    const result = await this.apiService.get<User>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as User;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string | boolean>): Promise<User | undefined> {
    const result = await this.apiService.post<User>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result);
    return result;
  }

  async attachToSite(
    userId: number,
    attributes: Record<string, string | number>,
  ): Promise<User | undefined> {
    const result = await this.apiService.post<User>(`${path}/${userId}/sites`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result);
    return result;
  }

  async deleteSite(userId: number, siteId: number): Promise<null> {
    return await this.apiService.delete(`${path}/${userId}/sites/${siteId}`);
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | boolean | null>,
  ): Promise<User | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as User;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: User): void {
    if (!item) {
      return;
    }
  }
}
